import React from "react";
import BarChart from "../../Charts/BarChart";
import { dataForChart } from "../../../utils/utility";
import Barchart from "../../Charts/BarChart";

export const TopPlayedChart = ({ chartTypeButton, chartData }) => {
	const { labels, data, type, showLegend, height, width, timePlayed } =
		chartData;
	return (
		<div className="flex pieWrapper">
			<div className="pieWrapper__dropdown">{chartTypeButton}</div>
			<Barchart
				labels={labels}
				data={dataForChart(data)}
				type={type}
				showLegend={showLegend}
				height={height}
				width={width}
			/>
			<h3 className="pieWrapper__totalPlayed">
				{timePlayed} hours played in total
			</h3>
		</div>
	);
};

export default TopPlayedChart;
