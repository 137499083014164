import React, {useState} from 'react'
import DeviceItem from '../../components/UI/DeviceItem'
import TopPlayedChart from '../../components/UI/TopPlayedChart'
import {connect} from 'react-redux'
import * as actions from '../../redux/actions/index'
import DropdownFilter from '../../utils/dropdown.class'
import Loader from "../../components/UI/Loader"
import Error from "../../components/UI/Error"
import { useHistory } from 'react-router-dom'
import {sum_devices} from '../../utils/utility'
import {Modal} from "@material-ui/core";
import TopPlayedGames from "../../components/UI/TopPlayedGames";
import GameRanking from '../../components/UI/GameRanking'


const Account = ({loading, getData, error, devices, activeRegion, activeOperationsDirector, setActiveDevice, activeCommunity, dateRange, setAccountChart, accountChart, skip, gamesSorting, setGamesSorting}) => {

    let _devices = Object.values(devices[activeRegion][activeOperationsDirector][activeCommunity])
    const history = useHistory();
    
    if(_devices.length === 1 && skip)
    {
        const device = _devices[0];
        setActiveDevice(device.deviceSN);
        history.replace({
            ...history.location,
            pathname: `/${activeRegion}/${activeOperationsDirector}/${activeCommunity}/${device.deviceSN}`,
        });
    }
    
    let _device = {..._devices[0]}
    sum_devices(_device, _devices)
    const {gamesTop} = _device

    //MODAL HANDLER
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        gamesTop.forEach(game => game.show = true)
        setModalOpen(false);
    };
    //MODAL HANDLER END

    // CHART

    const accountChartDropdown = new DropdownFilter({
        // filterNames: ['Top played devices', 'Top OMI score', 'Increased OMI'],
        filterNames: ['Top played devices', 'Top OMI score'],
        option: accountChart,
        setOption: setAccountChart,
        sourceArray: _devices
    })
    // CHART END


    const deviceWrapper = () => {
        return _devices.map((device, i) => {
            return (
                <DeviceItem key={device.deviceSN + i} deviceSN={device.deviceSN} timePlayed={device.timePlayed}/>
            )
        }).sort((item1, item2) => item2.props.timePlayed - item1.props.timePlayed)
    }


    if (loading) {
        return <Loader/>
    }
    if (error) {
        return <Error/>
    }


    //GAMES DROPDOWN
    const gamesSortDropdown = new DropdownFilter({
        // filterNames: ['Top OMI score', 'Most played games', 'Increased OMI games'],
        filterNames: ['Top OMI score', 'Most played games'],
        option: gamesSorting,
        setOption: setGamesSorting,
        sourceArray: gamesTop
    }) 
    //GAMES DROPDOWN END



    return (
        <div className='flex'>
            <div className='mainWrapper'>
                <TopPlayedChart chartTypeButton={accountChartDropdown.render()}
                    chartData={accountChartDropdown.chartData()}/>
                <TopPlayedGames gamesTop={gamesSortDropdown.sortedArray()} dropdown={gamesSortDropdown.render()}
                    openDialog={handleModalOpen}/>
            </div>
            <div className='sideBarWrapper'>
            <div className='devicesWrapper'>
                    {deviceWrapper()}
                </div>

            </div>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
            >
                <div className='modalWrapper flex'>
                    <GameRanking gamesArr={gamesSortDropdown.sortedArray()} dropdown={gamesSortDropdown.render()}
                                 dateRange={dateRange}/>
                </div>
            </Modal> 
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.analytics.loading,
    error: state.analytics.error,
    getData: state.analytics.getData,
    devices: state.analytics.devices,
    activeRegion: state.analytics.activeRegion,
    activeOperationsDirector: state.analytics.activeOperationsDirector,
    activeCommunity: state.analytics.activeCommunity,
    dateRange: state.analytics.dateRange,
    accountChart: state.analytics.accountChart,
    skip: state.analytics.skip,
    gamesSorting: state.analytics.gamesSorting,
})

export default connect(mapStateToProps, actions)(Account)