import React, {useState} from "react"
import {Button, Container, Modal} from '@material-ui/core'
import AppBarItem from "../AppBar/AppBarItem/AppBarItem"
import DateRangeIcon from '@material-ui/icons/DateRange'
import {makeStyles} from "@material-ui/core/styles"
import {withRouter, Link} from 'react-router-dom'
// import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import ComparePlaylists from "../ComparePlaylistsModal"
import {connect} from "react-redux";
import * as actions from '../../../redux/actions/index'

const useStyles = makeStyles((theme) => ({
    headerItem: {
        color: `${theme.palette.common.blue1}`,
        marginLeft: '3vw'
    },
    container: {
        background: `${theme.palette.common.white}`,
        height: '6vh',
        marginBottom: '5vh',
    },
    wrapper: {
        height: '100%',
        alignItems: 'center'
    },
    accName: {
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    smallLink: {
        color: `${theme.palette.common.blue1}`,
        display: 'flex',
        alignItems: 'center'
    },
    leftArrow: {
        fontSize: '6px',
        fontStyle: 'inherit'
    }

}))

const Header = ({
                    location,
                    userName,
                    userId,
                    loading,
                    setDateRange,
                    dateRange,
                    devices,
                    activeDevice,
                    activeRegion,
                    activeOperationsDirector,
                    activeCommunity,
                    activePlaylist,
                    playlists,
                    setSkip,
                }) => {

    const path = location.pathname
    const classes = useStyles()

    //Modal Handler
    const [modalOpen, setModalOpen] = useState(false)

    const handleModalOpen = () => {
        setModalOpen(true)
    }

    const handleModalClose = () => {
        devices.forEach(device => device.show = true)
        playlists.forEach(playlist => {
            playlist.show = true
            playlist.selected = false
        })
        setModalOpen(false)
    }
    //Modal Handler END

    //data structures for dropdown
    const dateItemsArray = [7, 30, 60, 90]

    const dateItems = [
        {
            name: `${dateRange} days`,
            isSubMenu: true,
            subItems: dateItemsArray.map(item => {
                return {
                    name: `${item} days`,
                    to: item,
                    isSubMenu: false
                }
            })
        },
    ]

    const clickHandler = to => setDateRange(to)

    const dateItemsButtons = () => {
        return dateItems.map(item => {
            return (
                <AppBarItem
                    key={item.name}
                    item={item}
                    onItemClick={clickHandler}
                />
            )
        })
    }

    //data structures for dropdown END


    //Render
    const accountPageRender = (
        <>
            <div className={`${classes.headerItem} flex-center`}>
                <DateRangeIcon/>
                {dateItemsButtons()}
            </div>
            {userId === process.env.REACT_APP_USERID && userName === 'Michal' ?
                <Button color='primary'><Link to='/multiplier'>Multiplier</Link></Button> : null
            }

        </>
    )

    const devicePageRender = (
        <>
            {/*<div className={`${classes.headerItem} flex-center`}>*/}
            {/*    <PlaylistPlayIcon/>*/}
            {/*    {playlistItemsButtons}*/}
            {/*</div>*/}
            {accountPageRender}
        </>
    )

    const playlistPageRender = (
        <>
            <div className={`${classes.headerItem} flex-center`}>
                {/* <CompareArrowsIcon/>
                <AppBarItem
                    item={{name: 'Compare'}}
                    onItemClick={handleModalOpen}
                /> 
                make sure to return this after presentation*/}
            </div>

            {accountPageRender}
        </>
    )

    if (path === '/login') {
        return (
            <div className={classes.container}>
                <Container maxWidth="lg" className={classes.container}>
                    <div className={`${classes.wrapper} flex-center`}>
                        <div>
                            <h2 className={classes.accName}>Please log in into your account</h2>
                        </div>
                    </div>
                </Container>
            </div>
        )

    }

    const RoutingLogic = ({to, linkName, prevLocation}) => {
        return (
            <div>
                <div className='flex'><Link to={to} onClick={() => {setSkip(false)}}><small className={classes.smallLink}>
                    <i className={classes.leftArrow}>&nbsp;{"\u25C0"}</i>&nbsp;{linkName}</small></Link></div>
                <h2 className={classes.accName}>{prevLocation}</h2>
            </div>
        )
    }


    return (
        <div className={classes.container}>
            <Container maxWidth="lg" className={classes.container}>
                <div className={`${classes.wrapper} flex-sBetween`}>
                    <div>
                        {path === '/' ? <h2 className={classes.accName}>{userName} &gt; regions</h2> : null}
                        {activeRegion != null && path === `/${activeRegion}` ?
                            <RoutingLogic to={'/'} linkName='Regions' prevLocation={`${userName} > ${activeRegion} > operation directors`}/> : null
                        }
                        {activeRegion != null && activeOperationsDirector != null && path === `/${activeRegion}/${activeOperationsDirector}` ?
                            <RoutingLogic to={`/${activeRegion}`} linkName={activeRegion} prevLocation={`${userName} > ${activeRegion} > ${activeOperationsDirector} > communities`}/> : null
                        }
                        {activeRegion != null && activeOperationsDirector != null && activeCommunity != null && path === `/${activeRegion}/${activeOperationsDirector}/${activeCommunity}` ?
                            <RoutingLogic to={`/${activeRegion}/${activeOperationsDirector}`} linkName={activeOperationsDirector}
                                          prevLocation={`${userName} > ${activeRegion} > ${activeOperationsDirector} > ${activeCommunity} > devices`}/> : null
                        }
                        {activeRegion != null && activeOperationsDirector != null && activeCommunity != null && activeDevice != null && path === `/${activeRegion}/${activeOperationsDirector}/${activeCommunity}/${activeDevice}` ?
                            <RoutingLogic to={`/${activeRegion}/${activeOperationsDirector}/${activeCommunity}`} linkName={activeCommunity}
                                          prevLocation={`${userName} > ${activeRegion} > ${activeOperationsDirector} > ${activeCommunity} > ${devices[activeRegion][activeOperationsDirector][activeCommunity][activeDevice].name} > playlists`}/> : null
                        }
                        {activeRegion != null && activeOperationsDirector != null && activeCommunity != null && activeDevice != null && activePlaylist != null && path === `/${activeRegion}/${activeOperationsDirector}/${activeCommunity}/${activeDevice}/${activePlaylist}` ?
                            <RoutingLogic to={`/${activeRegion}/${activeOperationsDirector}/${activeCommunity}/${activeDevice}`} linkName={devices[activeRegion][activeOperationsDirector][activeCommunity][activeDevice].name}
                                          prevLocation={`${userName} > ${activeRegion} > ${activeOperationsDirector} > ${activeCommunity} > ${devices[activeRegion][activeOperationsDirector][activeCommunity][activeDevice].name} > ${activePlaylist}`}/> : null
                        }
                    </div>
                    <div className='flex-sEvenly'>
                        {activeRegion != null && activeOperationsDirector != null && activeCommunity != null && activeDevice != null && activePlaylist != null && path === `/${activeRegion}/${activeOperationsDirector}/${activeCommunity}/${activeDevice}/${activePlaylist}` ? playlistPageRender : null}
                        {activeRegion != null && activeOperationsDirector != null && activeCommunity != null && activeDevice != null && path === `/${activeRegion}/${activeOperationsDirector}/${activeCommunity}/${activeDevice}` ? devicePageRender : null}
                        {activeRegion != null && activeOperationsDirector != null && activeCommunity != null && path === `/${activeRegion}/${activeOperationsDirector}/${activeCommunity}` && !loading ? accountPageRender : null}
                        {activeRegion != null && activeOperationsDirector != null && path === `/${activeRegion}/${activeOperationsDirector}` && !loading ? accountPageRender : null}
                        {activeRegion != null && path === `/${activeRegion}` && !loading ? accountPageRender : null}
                        {path === `/` && !loading ? accountPageRender : null}

                        <button id="download button" className="btn btn-primary" style={{margin: "25px"}}>Download JSON</button>
                    </div>
                </div>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                >
                    <div className='modalWrapper flex'>
                        <ComparePlaylists/>
                    </div>
                </Modal>
            </Container>
        </div>

    )
}

const mapStateToProps = (state) => ({
    userName: state.auth.userName,
    userId: state.auth.userId,
    loading: state.analytics.loading,
    dateRange: state.analytics.dateRange,
    activeDevice: state.analytics.activeDevice,
    activeRegion: state.analytics.activeRegion,
    activeOperationsDirector: state.analytics.activeOperationsDirector,
    activeCommunity: state.analytics.activeCommunity,
    activePlaylist: state.analytics.activePlaylist,
    devices: state.analytics.devices,
    playlists: state.analytics.playlists,
});


export default connect(mapStateToProps, actions)(withRouter(Header))
