import {useEffect} from 'react';
import './App.scss';
// Routing
import {Route, Switch} from 'react-router-dom';
import Routes from './routing/Routes';

// Redux
import {connect} from 'react-redux';

import * as actions from './redux/actions/index';

// Components
import Layout from './HOC/Layout/Layout';

// Material theme
import {ThemeProvider} from '@material-ui/core';
import theme from './components/UI/Theme/Theme';

function App(props) {
    const {authCheckState} = props

    useEffect(() => {
        authCheckState()
    }, [authCheckState]);

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Switch>
                    <Route component={Routes}/>
                </Switch>
            </Layout>
        </ThemeProvider>
    );
}


const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,
    };
};

export default connect(mapStateToProps, actions)(App)
