import React, { useState } from "react";
import TopPlayedChart from "../../components/UI/TopPlayedChart";
import TopPlayedGames from "../../components/UI/TopPlayedGames";
import PlaylistItem from "../../components/UI/PlaylistItem";
import SearchInput from "../../components/UI/SearchInput";
import { Modal } from "@material-ui/core";
import GameRanking from "../../components/UI/GameRanking";
import PlaylistNotFound from "../../components/UI/PlaylistNotFound";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { useParams } from "react-router-dom";
import DropdownFilter from "../../utils/dropdown.class";
import Loader from "../../components/UI/Loader";
import Error from "../../components/UI/Error";
import RangeColumnChart from "../../components/Charts/RangeColumnChart";

const Device = ({
	dateRange,
	deviceChart,
	playlistsSorting,
	gamesSorting,
	setDeviceChart,
	setPlaylistsSorting,
	setGamesSorting,
	activeRegion,
	activeCommunity,
	activeDevice,
	devices,
	loading,
	error,
}) => {
	const _activeDevice = devices[activeRegion][activeCommunity][activeDevice];
	const { gamesTop, deviceSN } = _activeDevice;
	const playlists = Object.values(_activeDevice.playlists);

	const { device } = useParams();

	if (device !== deviceSN) {
		window.location.replace("/");
	}

	//MODAL HANDLER
	const [modalOpen, setModalOpen] = useState(false);
	const handleModalOpen = () => {
		setModalOpen(true);
	};

	const handleModalClose = () => {
		gamesTop.forEach((game) => (game.show = true));
		setModalOpen(false);
	};
	//MODAL HANDLER END

	//CHART DROPDOWN
	const deviceChartDropdown = new DropdownFilter({
		// filterNames: ['Top played playlists', 'Top OMI score', 'Increased OMI'],
		filterNames: ["Top played playlists", "Top OMI score"],
		option: deviceChart,
		setOption: setDeviceChart,
		sourceArray: playlists,
	});
	//CHART DROPDOWN END

	//SEARCH DROPDOWN
	const playlistSortDropdown = new DropdownFilter({
		// filterNames: ['Most recent', 'A-Z', 'Increased OMI', 'Decreased OMI', 'Top played', 'Least played'],
		filterNames: ["Most recent", "A-Z", "Top played", "Least played"],
		option: playlistsSorting,
		setOption: setPlaylistsSorting,
		sourceArray: playlists,
	});

	const [playlistArr, setPlaylistArr] = useState(
		playlistSortDropdown.sortedArray()
	); //Used for search purposes only
	//SEARCH DROPDOWN END

	//GAMES DROPDOWN
	const gamesSortDropdown = new DropdownFilter({
		// filterNames: ['Top OMI score', 'Most played games', 'Increased OMI games'],
		filterNames: ["Top OMI score", "Most played games"],
		option: gamesSorting,
		setOption: setGamesSorting,
		sourceArray: gamesTop,
	});
	//GAMES DROPDOWN END

	//PLAYLISTS RENDER (UNDER SEARCH)
	const updateData = playlists.filter((playlist) => playlist.show);
	const playlistWrapper = () => {
		const yes = updateData
			.map((playlist) => {
				//Why did I call it "yes" ?
				if (!playlist.show) {
					return null;
				}
				return (
					<PlaylistItem
						key={playlist.id}
						name={playlist.name}
						omi={playlist.omi}
						id={playlist.id}
						deviceParam={device}
						playlist={playlist}
						timePlayed={playlist.timePlayed}
					/>
				);
			})
			.sort(
				(item1, item2) =>
					item2.props.timePlayed - item1.props.timePlayed
			);

		const foundSomething = playlists.find((playlist) => !!playlist.show);

		return foundSomething ? yes : <PlaylistNotFound />; // That's why
	};

	const playlistsColors = [
		"#00bcd4",
		"#ff9800",
		"#009688",
		"#673ab7",
		"#e91e63",
	];

	//PLAYLISTS RENDER (UNDER SEARCH) END

	if (loading) {
		return <Loader />;
	}
	if (error) {
		return <Error />;
	}
	console.log({ playlists });
	console.log({ _activeDevice });
	console.log({ powerStateReports: _activeDevice.powerStateReports });
	return (
		<div className="flex">
			<div className="mainWrapper">
				<TopPlayedChart
					chartTypeButton={deviceChartDropdown.render()}
					chartData={deviceChartDropdown.chartData()}
				/>

				<div className="playlistsWrapper">
					<RangeColumnChart
						playlists={playlists}
						powerStates={_activeDevice.powerStateReports}
						single={false}
						dateRange={dateRange}
						colors={["#ff9800", "#009688", "#044", "#00f", "#0f0"]} // supply more colors in case we have more than 3 playlists
						animations={{
							enabled: true,
							easing: "easeinout",
							speed: 50,
							animateGradually: {
								enabled: true,
								delay: 100,
							},
							dynamicAnimation: {
								enabled: true,
								speed: 50,
							},
						}}
					/>
				</div>
			</div>

			<div className="sideBarWrapper">
				<div className="device">
					<SearchInput
						array={playlistArr}
						setArray={setPlaylistArr}
						dropdown={playlistSortDropdown.render()}
						searchItemName="playlists"
					/>
					{playlistWrapper()}
				</div>
				<TopPlayedGames
					gamesTop={gamesSortDropdown.sortedArray()}
					dropdown={gamesSortDropdown.render()}
					openDialog={handleModalOpen}
				/>
			</div>
			<Modal open={modalOpen} onClose={handleModalClose}>
				<div className="modalWrapper flex">
					<GameRanking
						gamesArr={gamesSortDropdown.sortedArray()}
						dropdown={gamesSortDropdown.render()}
						dateRange={dateRange}
					/>
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	loading: state.analytics.loading,
	error: state.analytics.error,
	dateRange: state.analytics.dateRange,
	activeDevice: state.analytics.activeDevice,
	deviceChart: state.analytics.deviceChart,
	playlistsSorting: state.analytics.playlistsSorting,
	gamesSorting: state.analytics.gamesSorting,
	activeRegion: state.analytics.activeRegion,
	activeCommunity: state.analytics.activeCommunity,
	activeDevice: state.analytics.activeDevice,
	devices: state.analytics.devices,
});

export default connect(mapStateToProps, actions)(Device);
