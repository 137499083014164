import http from '../../services/httpAgent.service'
import * as actionTypes from './types'
import {deviceHandler, playlistHandler, seperateDevicesToRegions, seperateDevicesToOperationDirectors, seperateDevicesToCommunities, deviceDictionary, playlistDictionary, mergeIdenticalGames} from '../../utils/utility'
const json_properties = ["region", "operations_director", "community", "deviceName", "deviceSN", "playmode", "playlists", "name", "games", "sessions","startTime", "endTime",
"sessionLength", "gameConfig", "difficulty", "memory", "distraction", "engagement", "movement", "timePlayed",
"omi", "activePlaylists"];

export const getDataStart = () => {
    return {
        type: actionTypes.GET_DATA_START,
    }
}

export const getDataSuccess = (devices, playlists) => {
    return {
        type: actionTypes.GET_DATA_SUCCESS,
        devices,
        playlists
    }
}

export const getDataFail = () => {
    return {
        type: actionTypes.GET_DATA_FAIL
    }
}

export const setDateRange = (dateRange) => {
    return {
        type: actionTypes.SET_DATE_RANGE,
        dateRange
    }
}
//Filters

export const setAccountChart = (accountChart) => {
    return {
        type: actionTypes.SET_ACCOUNT_CHART,
        accountChart
    }
}

export const setDeviceChart = (deviceChart) => {
    return {
        type: actionTypes.SET_DEVICE_CHART,
        deviceChart
    }
}
export const setPlaylistsSorting = (playlistsSorting) => {
    return {
        type: actionTypes.SET_PLAYLISTS_SORTING,
        playlistsSorting
    }
}

export const setGamesSorting = (gamesSorting) => {
    return {
        type: actionTypes.SET_GAMES_SORTING,
        gamesSorting
    }
}

//Filters

export const setActiveDevice = (activeDevice) => {
    return {
        type: actionTypes.SET_ACTIVE_DEVICE,
        activeDevice
    }
}
export const setActiveRegion = (activeRegion) => {
    return {
        type: actionTypes.SET_ACTIVE_REGION,
        activeRegion
    }
}
export const setActiveOperationsDirector = (activeOperationsDirector) => {
    return {
        type: actionTypes.SET_ACTIVE_OPERATIONS_DIRECTOR,
        activeOperationsDirector
    }
}
export const setActiveCommunity = (activeCommunity) => {
    return {
        type: actionTypes.SET_ACTIVE_COMMUNITY,
        activeCommunity
    }
}
export const setActivePlaylist = (activePlaylist) => {
    return {
        type: actionTypes.SET_ACTIVE_PLAYLIST,
        activePlaylist
    }
}
//Skiping layers option

export const setSkip = (skip) => {
    return {
        type: actionTypes.SET_SKIP,
        skip
    }
}


//end

export const getData = (dateRange = 7) => {
    const dateTill = new Date().getTime()
    const dateFrom = dateTill - (dateRange * 24 * 60 * 60 * 1000)
    const token = sessionStorage.getItem('token')
    const headers = {
        Authorization: `Bearer ${token}`
    }
	

    return (dispatch) => {

        dispatch(getDataStart())
        // if(!token){
        //     dispatch(getDataFail())
        // }

        http
            .get(`${process.env.REACT_APP_ANALYTICS_SERVICE}/customer/data?to=${dateTill}&from=${dateFrom}`, headers)
            .then((data) => {
				
				if(data.error) {
					//find a better solution
					getData()
					return
				} 
				console.log("getData():");
				console.log({data});
				function createDataMap(data)
				{                
					const devices = data.map(device => deviceHandler(device))
					let dataMap = seperateDevicesToRegions(devices)
					Object.keys(dataMap).forEach((region) => {
						dataMap[region] = seperateDevicesToOperationDirectors(dataMap[region])
						Object.keys(dataMap[region]).forEach(operations_director => {
							dataMap[region][operations_director] = seperateDevicesToCommunities(dataMap[region][operations_director])
							Object.keys(dataMap[region][operations_director]).forEach((community) => {
								dataMap[region][operations_director][community] = deviceDictionary(dataMap[region][operations_director][community])
								Object.keys(dataMap[region][operations_director][community]).forEach((device) => {
									dataMap[region][operations_director][community][device].playlists = playlistDictionary(dataMap[region][operations_director][community][device].playlists)
									dataMap[region][operations_director][community][device].gamesTop = mergeIdenticalGames(dataMap[region][operations_director][community][device].gamesTop)
								})
							})
						})
					})
					return dataMap
				}
				const dataMap = createDataMap(data)
				console.log({dataMap});
                const playlists = data.flatMap(device => device.playlists.map(playlist => playlistHandler(playlist)))
				console.log({playlists})

				let btn = document.getElementById("download button")
                if (btn != null)
				{			
					let download_data = JSON.stringify(createDataMap(JSON.parse(JSON.stringify(data,json_properties,4))),4)
					btn.onclick = function () {
					download(download_data, "data[ "+new Date(dateFrom).toDateString() + " -- " + new Date(dateTill).toDateString() + " ].json", "JSON");
					};
        		}      
                dispatch(getDataSuccess(dataMap, playlists))
            })
            .catch((error) => {
                dispatch(getDataFail())
                console.log(error)
            })
    }
}
function download(data, strFileName, strMimeType) {
	
	var self = window, // this script is only for browsers anyway...
		u = "application/octet-stream", // this default mime also triggers iframe downloads
		m = strMimeType || u, 
		x = data,
		D = document,
		a = D.createElement("a"),
		z = function(a){return String(a);},
		
		
		B = self.Blob || self.MozBlob || self.WebKitBlob || z,
		BB = self.MSBlobBuilder || self.WebKitBlobBuilder || self.BlobBuilder,
		fn = strFileName || "download",
		blob, 
		b,
		ua,
		fr;

	//if(typeof B.bind === 'function' ){ B=B.bind(self); }
	
	if(String(this)==="true"){ //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
		x=[x, m];
		m=x[0];
		x=x[1]; 
	}
	
	
	
	//go ahead and download dataURLs right away
	if(String(x).match(/^data\:[\w+\-]+\/[\w+\-]+[,;]/)){
		return navigator.msSaveBlob ?  // IE10 can't do a[download], only Blobs:
			navigator.msSaveBlob(d2b(x), fn) : 
			saver(x) ; // everyone else can save dataURLs un-processed
	}//end if dataURL passed?
	
	try{
	
		blob = x instanceof B ? 
			x : 
			new B([x], {type: m}) ;
	}catch(y){
		if(BB){
			b = new BB();
			b.append([x]);
			blob = b.getBlob(m); // the blob
		}
		
	}
	
	
	
	function d2b(u) {
		var p= u.split(/[:;,]/),
		t= p[1],
		dec= p[2] == "base64" ? atob : decodeURIComponent,
		bin= dec(p.pop()),
		mx= bin.length,
		i= 0,
		uia= new Uint8Array(mx);

		for(i;i<mx;++i) uia[i]= bin.charCodeAt(i);

		return new B([uia], {type: t});
	 }
	  
	function saver(url, winMode){
		
		
		if ('download' in a) { //html5 A[download] 			
			a.href = url;
			a.setAttribute("download", fn);
			a.innerHTML = "downloading...";
			D.body.appendChild(a);
			setTimeout(function() {
				a.click();
				D.body.removeChild(a);
				if(winMode===true){setTimeout(function(){ self.URL.revokeObjectURL(a.href);}, 250 );}
			}, 66);
			return true;
		}
		
		//do iframe dataURL download (old ch+FF):
		var f = D.createElement("iframe");
		D.body.appendChild(f);
		if(!winMode){ // force a mime that will download:
			url="data:"+url.replace(/^data:([\w\/\-\+]+)/, u);
		}
		 
	
		f.src = url;
		setTimeout(function(){ D.body.removeChild(f); }, 333);
		
	}//end saver 
		

	if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
		return navigator.msSaveBlob(blob, fn);
	} 	
	
	if(self.URL){ // simple fast and modern way using Blob and URL:
		saver(self.URL.createObjectURL(blob), true);
	}else{
		// handle non-Blob()+non-URL browsers:
		if(typeof blob === "string" || blob.constructor===z ){
			try{
				return saver( "data:" +  m   + ";base64,"  +  self.btoa(blob)  ); 
			}catch(y){
				return saver( "data:" +  m   + "," + encodeURIComponent(blob)  ); 
			}
		}
		
		// Blob but not URL:
		fr=new FileReader();
		fr.onload=function(e){
			saver(this.result); 
		};
		fr.readAsDataURL(blob);
	}	
	return true;
} /* end download() */

