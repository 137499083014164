import React from "react";
import Chart from "react-apexcharts";
import {useTheme} from "@material-ui/core/styles";

const LineArea = ({series, height, width}) => {
    const {infoBlue, infoPurple, infoRed, infoYellow, blue1, gray3, black} = useTheme().palette.common
    return (
        <Chart
            type='area'
            height={height}
            width={width}
            series={[{
                //infoYellow
                name: 'Movement',
                data: series.movement
            }, {
                //infoPurple
                name: 'Memory',
                data: series.memory
            }, {
                //infoBlue
                name: 'Engagement',
                data: series.engagement
            }, {
                //infoRed
                name: 'Distraction',
                data: series.distraction
            // }, {
            //     //black
            //     name: 'OMI',
            //     data: series.omi
            }]}
            options={{
                title: {
                    text: 'Select a metric to display or hide from the chart:',
                    style: {
                        fontWeight:'normal',
                        color: gray3
                    },
                },
                chart: {
                    stacked: false,
                },
                colors: [infoYellow, infoPurple, infoBlue, infoRed, black],
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [3],
                    offsetY: -10,
                    style: {
                        fontSize: '12px',
                        colors: [blue1],
                    },
                    background: {
                        borderRadius: 9,
                        padding: 7,
                    },
                    formatter: (value, {seriesIndex, dataPointIndex, w}) => {
                        return "";
                    }
                },
                stroke: {
                    curve: 'smooth'
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 0.7,
                        opacityTo: 0,
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                xaxis: {
                    type: 'datetime'
                },
                yaxis: {
                    opposite: true
                },
                responsive: [
                    {
                        breakpoint: 600,
                        options: {
                            chart: {
                                width: "100%",
                                height: 250
                            },
                            legend: {
                                show: true
                            }
                        }
                    },
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: "100%"
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            }
            }


        />
    );
}

export default LineArea;
