import React from "react";
// import ProgressBar from "../ProgressBar/";

export const Score = ({name, value, color = 'Black'}) => {

    return (
        <div className='flex score'>
            <p className='score__name'>{name}</p>
            <h2 className={`score__value info${color}`}>{value}</h2>
            {/*<ProgressBar bold={true}/>*/}
        </div>
    )
}