import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
    // console.log(theme);
    return {
        dialogBody: {
            padding: '16px 24px 24px',
            minWidth: '376px',
        },
    };
});

export default function AppDialog(props) {
    const { open, handleClose, children } = props;
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            classes={{
                paper: classes.dialogBody,
            }}
        >
            {children}
        </Dialog>
    );
}
