import * as actionTypes from '../actions/types'
import {updateObject} from '../../utils/utility'

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    forgotPass: false,
    forgotPassSuccess: false,
    userName: null,
};

const authStart = (state, action) => {
    return updateObject(state, {error: null, loading: true})
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        userId: action.userId,
        userName: action.userName,
        error: null,
        loading: false,
    })
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    })
}

const authLogout = (state, action) => {
    return updateObject(state, {token: null, userId: null})
}

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, {authRedirectPath: action.path})
}

const forgotPassStart = (state, action) => {
    return updateObject(state, {forgotPass: true, loading: true, error: null});
};

const forgotPassSuccess = (state, action) => {
    return updateObject(state, {
        forgotPass: true,
        forgotPassSuccess: true,
        loading: false,
        error: null,
    });
};

const forgotPassFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
};

const forgotPassFinish = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        forgotPass: false,
        forgotPassSuccess: null,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action)

        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action)

        case actionTypes.AUTH_FAIL:
            return authFail(state, action)

        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action)

        case actionTypes.SET_AUTH_REDIRECT_PATH:
            return setAuthRedirectPath(state, action)

        case actionTypes.FORGOT_PASSWORD_START:
            return forgotPassStart(state, action);

        case actionTypes.FORGOT_PASSWORD_SUCCESS:
            return forgotPassSuccess(state, action);

        case actionTypes.FORGOT_PASSWORD_FAIL:
            return forgotPassFail(state, action);

        case actionTypes.FORGOT_PASSWORD_FINISH:
            return forgotPassFinish(state, action);

        default:
            return state;
    }
};

export default reducer
