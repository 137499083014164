import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Loader = () => {
  return (
      <div className='flex-center grayColor loader'>
        <h2>Gathering your data</h2>
        <h3>This may take a moment...</h3>
        <CircularProgress size={196} disableShrink/>
      </div>
  )
};
