import React from 'react'
import {Modal, Button} from '@material-ui/core';

const Error = () => {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        window.location.href = '/'
        setOpen(false);
    };

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <div className='modalAlert'>
                    <h3>Oh, something went wrong...</h3>
                    <br />
                    <p>Please try again.</p>
                    <br/>
                    <div className='flex' style={{justifyContent: 'flex-end'}}>
                        <Button variant="contained" color='primary' onClick={handleClose}>Close</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Error