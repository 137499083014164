import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";

export const CommunityItem = ({
	devices,
	region,
	operations_director,
	community,
	setActiveCommunity,
	setSkip,
	timePlayed,
}) => {
	const devicesCount = Object.keys(
		devices[region][operations_director][community]
	).length;
	return (
		<Link
			to={`/${region}/${operations_director}/${community}`}
			timePlayed={timePlayed}
			className="deviceItem"
			onClick={() => {
				setActiveCommunity(community);
				setSkip(true);
			}}
		>
			<div className="deviceItem__name">
				<h2>{community}</h2>
			</div>
			<div className="flex-sBetween grayColor deviceItem__info">
				<p className="devicseItem__info__playlist">
					<span>{devicesCount}</span>{" "}
					{devicesCount == 1 ? "device" : "devices"}
				</p>
				{/* <div className='flex-sBetween deviceItem__info__progress'>
                    <p className='deviceItem__info__progress__hours'>{timing(timePlayed)} in the
                        last {dateRange} days</p>
                    {<ProgressBar/>}
                </div> */}
			</div>
		</Link>
	);
};

const mapStateToProps = (state) => ({
	devices: state.analytics.devices,
	region: state.analytics.activeRegion,
	operations_director: state.analytics.activeOperationsDirector,
	dateRange: state.analytics.dateRange,
	timePlayed: state.analytics.timePlayed,
});

export default connect(mapStateToProps, actions)(CommunityItem);
