import React, {useState} from "react";
import GameRankingItem from '../GameRankingItem';
import SearchInput from "../SearchInput";

const GameRanking = ({gamesArr, dropdown, dateRange}) => {

    // const [scroll, setScroll] = useState(0);

    // const mainElement = useRef();
    //
    // const scrollerPositionDetector = () => {
    //     setScroll(mainElement.current.scrollTop)
    // }
    // ref={mainElement} onScroll={scrollerPositionDetector}
    // ${scroll > 0 ? 'fancyShadowOnlyBottom' : ''}

    const [games, setGames] = useState(gamesArr) //Used for search purposes only

    React.useEffect(() => {
        setGames(gamesArr);
    }, [gamesArr]);

    let gameNumber = 0;
    const gameRankingItem = games.map((game, i) => {
        if (game.show) {
            gameNumber++
            return (
                <div className='flex flex-center gameRanking__itemWrapper__item' key={game.name + i}>
                    <div className='flex-center numberWrapper'>
                        <h2 className='grayColor'>{gameNumber}.</h2>
                    </div>
                    <GameRankingItem game={game} dateRange={dateRange} />
                </div>
            )
        } else
            return null
    })


    return (
        <div className='flex gameRanking'>
            <SearchInput array={games} setArray={setGames} dropdown={dropdown} searchItemName='games' />
            <div className='gameRanking__itemWrapper'>
                {gameRankingItem}
            </div>
        </div>
    )
}

export default GameRanking