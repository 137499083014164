import React from "react";
import {Link} from 'react-router-dom'
// import ProgressBar from "../ProgressBar";
import {connect} from "react-redux";
import * as actions from "../../../redux/actions";
import {regionDeviceCount} from "../../../utils/utility";


export const RegionItem = ({devices, region, setActiveRegion, setSkip, timePlayed}) => {

    let communities_count = 0;
    Object.values(devices[region]).forEach((OD) => {communities_count += Object.keys(OD).length})
    
    const devicesCount = regionDeviceCount(devices[region])
    return (
        
        <Link to={`/${region}`} timePlayed={timePlayed} className='deviceItem' onClick={() => {
            setActiveRegion(region)
            setSkip(true)
        }}>
            <div className='deviceItem__name'>
                <h2>{region}</h2>
            </div>
            <div className='flex-sBetween grayColor deviceItem__info'>
                <p className='deviceItem__info__playlist'>
                    <span>{communities_count}</span> {communities_count == 1 ? 'community' : 'communities'}</p>
                <div className='flex-sBetween deviceItem__info__progress'>
                    <p className='deviceItem__info__progress__hours'>{devicesCount} {devicesCount == 1 ? 'device' : 'devices'}</p>
                    {/*<ProgressBar/>*/}
                </div>
            </div>
        </Link>
    )
}

const mapStateToProps = (state) => ({
    devices: state.analytics.devices,
    dateRange: state.analytics.dateRange
});

export default connect(mapStateToProps, actions)(RegionItem)