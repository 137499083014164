import React from 'react'
import {Button, Menu, MenuItem} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

export default function MenuDropDown(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { item, styling } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setAnchorEl(null);

    if (item.to) {
        props.onSubItemClick(item.to)
    }
  };

  const menuItems = item.subItems.map((item) => (
    <MenuItem key={item.name} onClick={() => handleClose(item)}>
      {item.name}
    </MenuItem>
  ));

  return (
    <>
      <Button
        className={styling.root}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {item.name}

        <ArrowDropDownIcon className={styling.dropDownIcon} />
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </>
  );
}
