import React from "react";
import GameRankingItemShort from "../GameRankingItemShort";
import {connect} from "react-redux";
import * as actions from "../../../redux/actions";
import {withRouter} from "react-router-dom";
import DropdownFilter from "../../../utils/dropdown.class";

const GameRankingCompare = ({games, dateRange, gamesSorting, setGamesSorting}) => {

    const gamesSortDropdown = new DropdownFilter({
        // filterNames: ['Top OMI score', 'Most played games', 'Increased OMI games'],
        filterNames: ['Top OMI score', 'Most played games'],
        option: gamesSorting,
        setOption: setGamesSorting,
        sourceArray: games
    })

    const gamesRankingItems = gamesSortDropdown.sortedArray().map((game, i) => {
        return (
            <div className='flex gameRankingCompare__itemWrapper__item' key={game.name + i}>
                <div className='flex-center numberWrapper'>
                    <h2 className='grayColor'>{i + 1}.</h2>
                </div>
                <GameRankingItemShort game={game} key={game.name + i} dateRange={dateRange}/>
            </div>
        )
    })

    return (
        <div className='flex gameRankingCompare'>
            <div className='gameRankingCompare__dropdown'>
            {gamesSortDropdown.render()}
            </div>

            <div className='flex gameRankingCompare__itemWrapper'>
                {gamesRankingItems}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    gamesSorting: state.analytics.gamesSorting,
});


export default connect(mapStateToProps, actions)(withRouter(GameRankingCompare))
