import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../redux/actions/index';

const PrivateRoute = ({
                          component: Component,
                          isAuthenticated,
                          loading,
                          ...rest
                      }) => {
    // console.log('isAuthenticated ', isAuthenticated);

    return (
        <Route
            {...rest}
            render={(props) =>
                !isAuthenticated && !loading ? (
                    <Redirect to="/login"/>
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

// PrivateRoute.propTypes = {
//   auth: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.token !== null,
    loading: state.auth.loading,
});

// const mapDispatchToProps = (dispatch) => {
//   return {
//     onTryAutoSignIn: () => dispatch(actions.authCheckState()),
//   };
// };

export default connect(mapStateToProps, actions)(PrivateRoute);
