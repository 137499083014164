// React
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';

// Redux internals
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';

// Reducers
import authReducer from './redux/reducers/auth';
import analyticsReducer from "./redux/reducers/analytics";

// state saver
// const saveState = (state) => {
//     try {
//         const serialisedState = JSON.stringify(state);
//         window.localStorage.setItem('app_state', serialisedState);
//     } catch (err) {
//         console.log(err)
//     }
// };

// Prevent Redux DevTools in production
const composeEnhancers = compose;
//    process.env.NODE_ENV === 'development'
//        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//        : compose;

// usedForDemo
// const composeEnhancers = compose

// Our reducers combined
const rootReducer = combineReducers({
    auth: authReducer,
    analytics: analyticsReducer
});

// Redux store
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

// Put state in app_state
// store.subscribe(() => {
//     saveState(store.getState());
// });

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);