// Auth
export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const FORGOT_PASSWORD_FINISH = 'FORGOT_PASSWORD_FINISH';

//Analytics
export const GET_DATA_START = 'GET_DATA_START'
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS'
export const GET_DATA_FAIL = 'GET_DATA_FAIL'

export const SET_DATE_RANGE = 'SET_DATE_RANGE'
export const SET_ACCOUNT_CHART = 'SET_ACCOUNT_CHART'
export const SET_DEVICE_CHART = 'SET_DEVICE_CHART'
export const SET_PLAYLISTS_SORTING = 'SET_PLAYLISTS_SORTING'
export const SET_GAMES_SORTING = 'SET_GAMES_SORTING'

export const SET_ACTIVE_COMMUNITY = 'SET_ACTIVE_COMMUNITY'
export const SET_ACTIVE_REGION = 'SET_ACTIVE_REGAIN'
export const SET_ACTIVE_OPERATIONS_DIRECTOR = 'SET_ACTIVE_OPERATIONS_DIRECTOR'
export const SET_ACTIVE_DEVICE = 'SET_ACTIVE_DEVICE'
export const SET_ACTIVE_PLAYLIST = 'SET_ACTIVE_PLAYLIST'
export const SET_SKIP = 'SET_SKIP'

