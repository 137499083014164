import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {CircularProgress} from '@material-ui/core';

const useStyle = makeStyles({
  root: {
    margin: '0 auto',
  },
});

const Spinner = (props) => {
  const classes = useStyle();

  return (
    <CircularProgress
      classes={{ root: classes.root }}
      color={props.color}
      size={props.size}
    />
  );
};

export default Spinner;
