import AppBarItem from "../components/UI/AppBar/AppBarItem/AppBarItem";
import React from "react";
import { calculateAnalytics } from "./utility";

export default class DropdownFilter {
    constructor({filterNames, option, sourceArray, setOption}) {
        this.filterNames = filterNames
        this.option = option
        this.setOption = setOption
        this.sourceArray = sourceArray
    }

    data = () => {  //You need to know your sourceArray structure by this point. is It devices or playlists

        // console.log(this.option)
        switch (this.option) {
            //CHARTS
            case 'Top played devices':
                return this.sourceArray.map(item => Math.floor(item.timePlayed / (60 * 60 * 1000)))
            case 'Top played playlists':
                return this.sourceArray.map(item => Math.floor(item.timePlayed / (60 * 60 * 1000)))
            case 'Top OMI score':
                return this.sourceArray.map(item => calculateAnalytics(item).omi)
            //CHARTS END
            default:
                return [404]
        }
    }

    sortedArray = () => {
        switch (this.option) {
            //PLAYLIST SORT
            case 'Most recent':
                return this.sourceArray.sort((a, b) => new Date(b.lastSession.endTime) - new Date(a.lastSession.endTime))
            case 'A-Z':
                return this.sourceArray.sort((a, b) => a.name.localeCompare(b.name))
            // case 'Increased OMI':
            //     return this.sourceArray
            // case 'Decreased OMI':
            //     return this.sourceArray
            case 'Top played':
                return this.sourceArray.sort((a, b) => b.timePlayed - a.timePlayed)
            case 'Least played':
                return this.sourceArray.sort((a, b) => a.timePlayed - b.timePlayed)
            //PLAYLIST SORT

            //GAMES SORT
            case 'Top OMI score':
                return this.sourceArray.sort((a, b) => calculateAnalytics(b).omi - calculateAnalytics(a).omi)
            case 'Most played games':
                return this.sourceArray.sort((a, b) => b.timePlayed - a.timePlayed)
            // case 'Increased OMI games':
            //     return this.sourceArray.sort((a, b) => a.omi - b.omi)
            //GAMES SORT END
            default:
                return this.sourceArray
        }
    }

    chartData = () => ({
        labels: this.sourceArray.map(item => item.name),
        data: this.data(),
        showLegend: true,
        height: 250,
        width: 300,
        timePlayed:
            this.sourceArray.length === 0 ? 0
                : this.sourceArray.reduce((acc, curr) => {
                    const hours = Math.floor(curr.timePlayed / (1000 * 60 * 60))
                    return acc + hours
                }, 0)
    })

    dropdown = () => ([
        {
            name: this.option,
            isSubMenu: true,
            subItems: this.filterNames.map(name => ({
                name,
                to: name,
                isSubMenu: false,
            }))
        }
    ])


    chartTypeHandler = to => this.setOption(to)

    render = () => {
        return this.dropdown().map(item => {
            return (
                <AppBarItem
                    key={item.name}
                    item={item}
                    onItemClick={this.chartTypeHandler}
                />
            )
        })
    }
}