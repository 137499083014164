import React from "react";
// import ProgressBar from "../ProgressBar";
import noImage from "../../../assets/image/noImage.svg";
import {calculateAnalytics, lastSessionTiming, timing} from "../../../utils/utility";

const GameRankingItemShort = ({game, dateRange}) => {

    const {name, thumbnailURL, lastSession, timePlayed} = game
    const {startTime, sessionLength} = lastSession
    const {omi} = calculateAnalytics(game)
    return (
        <div className='flex-center gameRankingItemShort'>
            <div className='gameRankingItemShort__imgWrapper'>
                <img src={thumbnailURL ? thumbnailURL : noImage} alt='gameIcon'/>
            </div>
            <div className='gameRankingItemShort__infoWrapper'>
                <h3 className='gameRankingItemShort__infoWrapper__firstLine'>{name}</h3>
                <div className='flex gameRankingItemShort__infoWrapper__secondLine grayColor'>
                    <p>{timing(timePlayed)} in the last {dateRange} days</p>
                    <p>{lastSessionTiming(startTime, sessionLength)}</p>
                </div>
                <div className='flex-sEvenly gameRankingItemShort__infoWrapper__thirdLine'>
                    <div className='flex grayColor'>
                        <h4>{omi} OMI</h4>
                        {/*<ProgressBar bold={false}/>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameRankingItemShort