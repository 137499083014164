import {unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

const fonts = `'Open Sans', sans-serif, Roboto`;

// Colors Palette
const blue0 = '#1D83AF';
const blue1 = '#37A0CE';
const blue2 = '#CEE8F2';
const blue3 = '#EBF5F9';
const black = '#111213';
const gray1 = '#4B5058';
const gray2 = '#676E79';
const gray3 = '#808893';
const gray4 = '#DEE0E3';
const gray5 = '#F4F5F5';
const white = '#FFFFFF';
const red = '#DE3546';
const green = '#1FC180';
const yellow = '#E0B43E';
const infoPurple = '#8779D1';
const infoRed = '#ED6C7B';
const infoYellow = '#F5BD56';
const infoGreen = '#6EE3AB';
const infoBlue = '#4CA0F5';

export default createMuiTheme({
    palette: {
        primary: {
            main: blue1,
            contrastText: white
        },
        background: {
            default: '#ebf5f9'
        },
        common: {
            white: white,
            blue0: blue0,
            blue1: blue1,
            blue2: blue2,
            blue3: blue3,
            black: black,
            gray1: gray1,
            gray2: gray2,
            gray3: gray3,
            gray4: gray4,
            gray5: gray5,
            red: red,
            green: green,
            yellow: yellow,
            infoPurple: infoPurple,
            infoRed: infoRed,
            infoYellow: infoYellow,
            infoGreen: infoGreen,
            infoBlue: infoBlue,
        },
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#fff',
            },
        },
        MuiToolbar: {
            gutters: {
                [defaultTheme.breakpoints.up('sm')]: {
                    paddingLeft: '32px',
                    paddingRight: '32px',
                },
            },
        },
        MuiInput: {
            input: {
                '&::placeholder': {
                    color: gray3,
                },
                color: blue1, // if you also want to change the color of the input, this is the prop you'd use
            },
        },
    },
    typography: {
        fontFamily: fonts,
    },
});
