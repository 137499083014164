import React from "react";
// import ProgressBar from "../ProgressBar";
import {timing, lastSessionTiming} from "../../../utils/utility";
import noImage from '../../../assets/image/noImage.svg'
import { calculateAnalytics } from "../../../utils/utility";

const GameRankingItem = ({game, dateRange}) => {

    const {name, timePlayed, lastSession, thumbnailURL} = game;
    const {engagement, movement, memory, distraction, omi} = calculateAnalytics(game);
    const {startTime, sessionLength} = lastSession
    return (
        <div className='flex gameRankingItem'>
            <div className='gameRankingItem__imgWrapper'>
                <img src={thumbnailURL ? thumbnailURL : noImage} alt='gameIcon'/>
            </div>
            <div className='gameRankingItem__infoWrapper'>
                <h3 className='gameRankingItem__infoWrapper__firstLine'>{name}</h3>
                <div className='flex-sBetween gameRankingItem__infoWrapper__secondLine grayColor'>
                    <p style={{color: "black", fontWeight: "bold"}}>{timing(timePlayed)} in the last {dateRange} days</p>
                    <p>{lastSessionTiming(startTime, sessionLength)}</p>
                </div>
                <div className='flex-sEvenly gameRankingItem__infoWrapper__thirdLine'>
                    <div className='flex grayColor'>
                        <h4>{omi} OMI</h4>
                        {/*<ProgressBar bold={false}/>*/}
                    </div>
                    <div className='flex grayColor'>
                        <p>{movement} Movement</p>
                        {/*<ProgressBar bold={false}/>*/ }
                    </div>
                    <div className='flex grayColor'>
                        <p>{memory} Memory</p>
                        {/*<ProgressBar bold={false}/>*/}
                    </div>
                    <div className='flex grayColor'>
                        <p>{engagement} Engagement</p>
                        {/*<ProgressBar bold={false}/>*/}
                    </div>
                    <div className='flex grayColor'>
                        <p>{distraction} Distraction</p>
                        {/*<ProgressBar bold={false}/>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameRankingItem