import http from "../../services/httpAgent.service"
import * as actionTypes from './types'

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    }
}

export const authSuccess = (token, userId, userName) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token,
        userId,
        userName
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
    }
}

export const logout = () => {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('expirationDate')
    sessionStorage.removeItem('userId')

    return {
        type: actionTypes.AUTH_LOGOUT,
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(logout())
        }, expirationTime * 1000)
    }
}

export const auth = (email, password) => {
    return (dispatch) => {
        dispatch(authStart())

        const authData = {
            email,
            password,
        }

        const url =`${process.env.REACT_APP_USERS_SERVICE}/users/login`

        // Authenticate ...
        http
            .post(url, authData)
            .then((data) => {

                // const expirationDate = new Date(
                //   new Date().getTime() + data.expiresIn * 1000
                // )

                const minutes = 180

                // minutes * 60 seconds * 1000 milliseconds

                const expirationDate = new Date(
                    new Date().getTime() + minutes * 60 * 1000
                )

                // console.log(data)

                const userName = data.user.firstName.charAt(0).toUpperCase() + data.user.firstName.slice(1) //Just to capitalise the first letter
                sessionStorage.setItem('token', data.token)
                sessionStorage.setItem('expirationDate', expirationDate)
                sessionStorage.setItem('userId', data.user.userID)
                sessionStorage.setItem('userName', userName)

                dispatch(authSuccess(data.token, data.user.userID, userName))
                // dispatch(checkAuthTimeout(data.expiresIn))
            })
            .catch((err) => {
                console.log(err.response)
                dispatch(authFail(err.response))
            })
    }
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path,
    }
}

export const authCheckState = () => {
    return (dispatch) => {
        const token = sessionStorage.getItem('token')

        if (!token) {
            dispatch(logout())
        } else {
            const expirationDate = new Date(sessionStorage.getItem('expirationDate'))

            if (expirationDate <= new Date()) {
                console.log('expired. loggin out')

                dispatch(logout())
            } else {
                const userId = sessionStorage.getItem('userId')
                const userName = sessionStorage.getItem('userName')
                dispatch(authSuccess(token, userId, userName))

                const expirationDateInMs =
                    (expirationDate.getTime() - new Date().getTime()) / 1000

                dispatch(checkAuthTimeout(expirationDateInMs))
            }
        }
    }
}

export const forgotPass = (email) => {
  return (dispatch) => {
    dispatch(forgotPassStart());

    if (email) {
      const data = {
        email: email,
      };

      const url = `${process.env.REACT_APP_USERS_SERVICE}/users/passwordReset`

      // Authenticate ...
      http
        .post(url, data)
        .then((response) => {
          // console.log(response);

          dispatch(forgotPassSuccess());
        })
        .catch((err) => {
          dispatch(forgotPassFail(err.response.data));
        });
    }
  };
};

export const forgotPassStart = () => {
  return {
    type: actionTypes.FORGOT_PASSWORD_START,
  };
};

export const forgotPassSuccess = () => {
  return {
    type: actionTypes.FORGOT_PASSWORD_SUCCESS,
  };
};

export const forgotPassFail = (error) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_FAIL,
    error: error,
  };
};

export const forgotPassFinish = () => {
  return {
    type: actionTypes.FORGOT_PASSWORD_FINISH,
  };
};
