import React, { useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import { useTheme } from "@material-ui/core/styles";
require("./RangeColumnChart.scss");

console.log = () => {};

const RangeColumnChart = ({
	playlists,
	dateRange,
	colors,
	single,
	powerStates = [],
	animations = null,
}) => {
	const { infoBlue, infoPurple, infoRed, infoYellow, blue1, gray3, black } =
		useTheme().palette.common;

	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	// utility functions
	const startOfToday = (advanceDaysBy = 0) => {
		const startOfToday = new Date();
		startOfToday.setUTCHours(0, 0, 0, 0);
		return startOfToday.getTime();
	};
	const endOfToday = () => {
		const endOfToday = new Date();
		endOfToday.setUTCHours(0, 0, 0, 0);
		// endOfToday.setDate(endOfToday.getDate() + 1);
		endOfToday.setUTCHours(24, 0, 0, 0);
		return endOfToday.getTime();
	};

	const stringToTime = (dateString) => {
		const date = new Date(dateString);
		date.setUTCDate(today.getDate());
		date.setUTCMonth(today.getMonth());
		const time = date.getTime();
		return time;
	};

	// get the start and end times for the chart
	// recieves milliseconds since epoch
	// returns the value of the tick on the x axis
	const milliscondsToTableRow = (milliseconds) => {
		return `${new Date(milliseconds).getUTCDate()} ${
			monthNames[new Date(milliseconds).getUTCMonth()]
		}`;
	};

	// end utility functions

	const today = new Date();
	const dayOfWeek = new Array(dateRange).fill(0).map((_, i) => {
		const curr = new Date();
		curr.setUTCDate(curr.getUTCDate() - i);
		return `${curr.getUTCDate()} ${monthNames[curr.getUTCMonth()]}`;
	});

	const gameSeries = {};
	let playlist = false;
	if (playlists.length === 1) {
		playlist = playlists[0];
	}
	playlist &&
		playlist.games.map((game) => {
			game.sessions.map((session) => {
				const startHour = stringToTime(session.startTime);
				const endHour = stringToTime(session.endTime);

				if (game.name.toLowerCase() === "poster puzzle") {
					console.log({ game });
					console.log({});
				}
				if (!gameSeries[game.name]) {
					gameSeries[game.name] = {
						data: [],
					};
				}
				//in case game was played over midnight, split it to 2 different series
				if (endHour < startHour) {
					gameSeries[game.name].data.push({
						x: `${new Date(session.startTime).getUTCDate()} ${
							monthNames[
								new Date(session.startTime).getUTCMonth()
							]
						}`,
						y: [startHour, endOfToday()],
					});

					gameSeries[game.name].data.push({
						x: `${new Date(
							new Date(session.endTime).valueOf()
						).getUTCDate()} ${
							monthNames[new Date(session.endTime).getUTCMonth()]
						}`,
						y: [startOfToday(), endHour],
					});
				} else {
					gameSeries[game.name].data.push({
						x: `${new Date(session.startTime).getUTCDate()} ${
							monthNames[
								new Date(session.startTime).getUTCMonth()
							]
						}`,
						y: [startHour, endHour],
					});
				}
			});
		});

	console.log({ gameSeries });
	console.log({ playlists });

	const series = single
		? Object.keys(gameSeries).map((game, ind) => {
				return {
					tooltipMessage: `${game} was played`,
					color: colors[ind % colors.length],
					name: game,
					data: gameSeries[game].data,
				};
		  })
		: playlists.map((playlist, ind) => {
				return {
					tooltipMessage: `${playlist.name} was running a game`,
					color: colors[ind % colors.length],
					name: playlist.name,
					data: playlist.games.map((game) => {
						return {
							x: `${new Date(
								game.lastSession.startTime
							).getUTCDate()} ${
								monthNames[
									new Date(
										game.lastSession.startTime
									).getUTCMonth()
								]
							}`,
							y: [
								stringToTime(game.lastSession.startTime),
								stringToTime(game.lastSession.endTime),
							],
						};
					}),
				};
		  });
	// console.log({ series });
	function getDifferenceInDays(date1, date2) {
		const diffInMs = Math.abs(date2 - date1);
		return diffInMs / (1000 * 60 * 60 * 24);
	}
	const onStates = [];
	const offStates = [];
	let powerStatesFiltered = [];
	const calculateOnOffStates = () => {
		powerStates.push({
			state: powerStates[powerStates.length - 1].state,
			dateTime: new Date().getTime() / 1000,
		});
		powerStates.push({ state: "stop" });
		for (let i = 0; i < powerStates.length - 1; i++) {
			let start = powerStates[i].dateTime * 1000;
			let end = powerStates[i + 1].dateTime * 1000;
			console.log(`enter while loop
				start=${new Date(start).toLocaleString()}
				end=${new Date(end).toLocaleString()}
				`);
			if (getDifferenceInDays(today.getTime(), start) > dateRange - 1) {
				i++;
				continue;
			}

			let arrToPush;

			// powerStates[i].state === "ON" ? onStates : offStates;

			let arrToPushName = powerStates[i].state === "ON" ? "on" : "off";
			while (start < end) {
				let targetTime = start + 1000 * 60 * 60 * 24;
				if (targetTime > end) {
					targetTime = end;
				}
				if (
					powerStates[i].state === "ON" &&
					powerStates[i + 1].state === "OFF"
				) {
					arrToPush = onStates;
				} else if (
					powerStates[i].state === "OFF" &&
					powerStates[i + 1].state === "ON"
				) {
					arrToPush = offStates;
				} else if (
					powerStates[i].state === "OFF" &&
					powerStates[i + 1].state === "OFF"
				) {
					arrToPush = offStates;
				} else if (
					powerStates[i].state === "ON" &&
					powerStates[i + 1].state === "ON"
				) {
					arrToPush = onStates;
				}
				const startDate = new Date(start);
				const targetDate = new Date(targetTime);
				const startHour = startDate.getUTCHours();
				const endHour = targetDate.getUTCHours();

				// if (startHour === endHour) {
				// 	console.log(`skipping ${startDate} ${targetDate}`);
				// 	start += 1000 * 60 * 60 * 24; // add one day
				// 	continue;
				// }

				console.log(`${new Date(start)} ${new Date(targetTime)}`);
				console.log(
					`From ${milliscondsToTableRow(
						start
					)} to ${milliscondsToTableRow(
						targetTime
					)} [${startHour} to ${endHour}]was ${arrToPushName}`
				);
				if (endHour <= startHour) {
					const endOfDay = new Date(start);
					endOfDay.setUTCHours(23, 59, 59);
					// endOfDay.setUTCHours(0, 0, 0);
					// endOfDay.setUTCDate(today.getUTCDate() + 1);
					endOfDay.setUTCDate(today.getUTCDate());
					endOfDay.setUTCMonth(today.getUTCMonth());
					const startOfDay = new Date(start);
					startOfDay.setUTCHours(0, 0, 0);
					startOfDay.setUTCDate(today.getUTCDate() + 1);
					startOfDay.setUTCMonth(today.getUTCMonth());
					arrToPush.push({
						x: milliscondsToTableRow(start),
						y: [start, endOfDay.getTime()],
					});
					const targetDate = new Date(targetTime);
					targetDate.setUTCDate(today.getUTCDate());
					targetDate.setUTCMonth(today.getUTCMonth());
					arrToPush.push({
						x: milliscondsToTableRow(targetTime),
						y: [startOfDay.getTime(), targetDate.getTime()],
					});
					console.log(`split day ${milliscondsToTableRow(
						start
					)} to ${milliscondsToTableRow(targetTime)}
					;
					[${startOfDay},${endOfDay}] to [${startOfDay},${targetDate}]);
					
					`);
				} else {
					const startDate = new Date(start);
					const targetDate = new Date(targetTime);
					startDate.setUTCDate(today.getUTCDate());
					startDate.setUTCMonth(today.getUTCMonth());
					targetDate.setUTCDate(today.getUTCDate());
					targetDate.setUTCMonth(today.getUTCMonth());
					console.log(
						`pushed to ${arrToPushName} ${startDate.toLocaleString()} to ${targetDate.toLocaleString()}`
					);
					arrToPush.push({
						x: milliscondsToTableRow(start),
						y: [startDate.valueOf(), targetDate.valueOf()],
					});
				}

				start += 1000 * 5 * 60 * 24; // add half day
			}
		}

		powerStates.forEach((powerState) => {
			powerState["date"] = new Date(powerState.dateTime * 1000);
		});
		console.log({ powerStates });
		console.log({ powerStatesFiltered });
	};

	if (powerStates.length > 0) {
		calculateOnOffStates();
	}

	// console.log({ onStates });
	// onStates.map((state) => {
	// 	console.log(state);
	// 	console.log(
	// 		milliscondsToTableRow(state.y[0]),
	// 		milliscondsToTableRow(state.y[1])
	// 	);
	// 	console.log(
	// 		new Date(state.y[0]).getUTCHours(),
	// 		new Date(state.y[1]).getUTCHours()
	// 	);
	// });
	// console.log({ offStates });

	const stateSeries = [
		{
			name: "",
			color: "#fff", // invisible color
			data: dayOfWeek.map((day, index) => {
				return {
					x: day,
					y: [0, 0],
				};
			}),
		},
		{
			tooltipMessage: "The device was off",
			color: single ? "#fff" : "#2d2d2d",
			name: single ? "" : "Off",
			data: offStates.map((state) => {
				const startDate = new Date(state.y[0]);
				const endDate = new Date(state.y[1]);
				startDate.setUTCDate(today.getUTCDate());
				endDate.setUTCDate(today.getUTCDate());
				startDate.setUTCMonth(today.getUTCMonth());
				endDate.setUTCMonth(today.getUTCMonth());
				const startTime = startDate.getTime();
				const endTime = endDate.getTime();
				return {
					x: state.x,
					y: [startTime, endTime],
				};
			}),
		},
		{
			tooltipMessage: "The device was on",
			color: single ? "#fff" : "#f00",
			name: single ? "" : "On",
			data: onStates.map((state) => {
				const startDate = new Date(state.y[0]);
				const endDate = new Date(state.y[1]);
				startDate.setUTCDate(today.getUTCDate());
				endDate.setUTCDate(today.getUTCDate());
				startDate.setUTCMonth(today.getUTCMonth());
				endDate.setUTCMonth(today.getUTCMonth());
				const startTime = startDate.getTime();
				const endTime = endDate.getTime();
				return {
					x: state.x,
					y: [startTime, endTime],
				};
			}),
		},

		...series,
	];

	const state = {
		series: stateSeries,

		options: {
			title: {
				text: `Select a ${
					playlists.length === 1 ? "game" : "playlist"
				} to display or hide it's playtime during the last ${dateRange} days`,
				style: {
					fontWeight: "normal",
					color: gray3,
				},
			},
			chart: {
				type: "bar",
			},
			plotOptions: {
				bar: {
					horizontal: true,
					barHeight: "50%",
					rangeBarGroupRows: true,
				},
			},
			// colors: stateColors,
			colors: colors,
			// colors: [
			// 	stateSeries.map((serie) => {
			// 		return function (opts) {
			// 			// console.log(`opts: ${opts}`);
			// 			const { w, seriesIndex, dataPointIndex, value } = opts;
			// 			const series = w.config.series[seriesIndex];
			// 			const seriesColor = series.color;
			// 			if (!seriesColor) {
			// 				return "#000";
			// 			}
			// 			const seriesName = series.name;
			// 			console.log({ seriesName });
			// 			// return colors[seriesIndex % colors.length];
			// 			return seriesColor;
			// 		};
			// 	}),
			// ],

			fill: {
				type: "solid",
			},
			xaxis: {
				type: "datetime",
			},
			yaxis: {
				tickAmount: 18,
				min: startOfToday(),
				max: endOfToday(),
			},
			legend: {
				position: "right",
			},
			tooltip: {
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					// get the data about the series that was selected to show the tooltip
					const seriesTooltip = w.config.series[seriesIndex];
					const { x, y } = seriesTooltip.data[dataPointIndex];
					const seriesName = seriesTooltip.name;
					let color = w.config.colors[seriesIndex];
					if (color && typeof color == "function") {
						color = color({ seriesIndex, dataPointIndex, w });
					}

					if (!seriesName) {
						return "";
					}
					const message = seriesTooltip.tooltipMessage;

					const datetimeToString = (datetime) => {
						const date = new Date(datetime);
						date.setDate(parseInt(x.substring(0, 2)));
						return date.toLocaleTimeString();
					};
					const startDate = datetimeToString(y[0]);
					const endDate = datetimeToString(y[1]);

					return `
						<div class=""
							style="padding: 5px 10px; border-radius: 5px;"
						>
							<span class="series-name" 
								style="
									color: ${color || "green"};
								"
								>${message}
							</span>
							<p>From ${startDate} to ${endDate} </p>
						</div>
					`;
				},
			},
		},
	};

	// apply custom animations if provided
	if (animations) {
		state.options.chart.animations = animations;
	}

	const heightForDaterange = (dateRange) => {
		switch (dateRange) {
			case 7:
				return 600;
			case 30:
				return 1000;
			case 60:
				return 1400;
			case 90:
				return 2000;
		}
		return 600;
	};
	return (
		<div className="chartWrapper">
			<Chart
				options={state.options}
				series={state.series}
				type="rangeBar"
				height={heightForDaterange(dateRange)}
			/>
		</div>
	);
};

export default RangeColumnChart;
