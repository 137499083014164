// import React from 'react';

// import classes from './Button.module.css';

// const button = (props) => (
// <button
//   disabled={props.disabled}
//   className={[classes.Button, classes[props.btnType]].join(' ')}
//   onClick={props.clicked}
// >
//     {props.children}
//   </button>
// );

// export default button;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function AppButton(props) {
  const {
    actionText = 'Submit',
    disabled,
    clicked,
    variant = 'contained',
    color = 'default',
    disableFocusRipple = false,
    disableRipple = false,
  } = props;

  const classes = useStyles();

  let button = (
    <Button
      disabled={disabled}
      className={props.className}
      // className={[classes.Button, classes[props.btnType]].join(' ')}
      variant={variant}
      onClick={clicked}
      color={color}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
    >
      {actionText}
    </Button>
  );

  return <div className={classes.root}>{button}</div>;
}
