import * as actionTypes from '../actions/types';
import {updateObject} from "../../utils/utility";

const initialState = {
    loading: false,
    error: false,
    devices: [{
        name: 'loading',
        timePlayed: 'loading'
    }],
    playlists: [{
        name: 'loading',
        timePlayed: 'loading'
    }],
    dateRange: 7,
    accountChart: 'Top played devices',
    deviceChart: 'Top played playlists',
    playlistsSorting: 'Most recent',
    gamesSorting: 'Most played games',
    activeDevice: {},
    activePlaylist: {},
};

const getDataStart = (state, action) => {
    return updateObject(state, {loading: true})
};

const getDataSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        devices: action.devices,
        playlists: action.playlists
    })
}

const getDataFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: true
    })
}

const setDateRange = (state, action) => {
    return updateObject(state, {
        dateRange: action.dateRange
    })
}

//SORTING/CHARTS
const setAccountChart = (state, action) => {
    return updateObject(state, {
        accountChart: action.accountChart
    })
}
const setDeviceChart = (state, action) => {
    return updateObject(state, {
        deviceChart: action.deviceChart
    })
}
const setPlaylistsSorting = (state, action) => {
    return updateObject(state, {
        playlistsSorting: action.playlistsSorting
    })
}
const setGamesSorting = (state, action) => {
    return updateObject(state, {
        gamesSorting: action.gamesSorting
    })
}
//SORTING/CHARTS END

const setActiveDevice = (state, action) => {
    return updateObject(state, {
        activeDevice: action.activeDevice
    })
}
const setActiveRegion = (state, action) => {
    return updateObject(state, {
        activeRegion: action.activeRegion
    })
}

const setActiveOperationsDirector = (state, action) => {
    return updateObject(state, {
        activeOperationsDirector: action.activeOperationsDirector
    })
}

const setActiveCommunity = (state, action) => {
    return updateObject(state, {
        activeCommunity: action.activeCommunity
    })
}

const setActivePlaylist = (state, action) => {
    return updateObject(state, {
        activePlaylist: action.activePlaylist
    })
}

//skip layers option

const setSkip = (state, action) => {
    return updateObject(state, {
        skip: action.skip
    })
}

//end

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DATA_START:
            return getDataStart(state, action)

        case actionTypes.GET_DATA_SUCCESS:
            return getDataSuccess(state, action)

        case actionTypes.GET_DATA_FAIL:
            return getDataFail(state, action)

        case actionTypes.SET_DATE_RANGE:
            return setDateRange(state, action)

        case actionTypes.SET_ACCOUNT_CHART:
            return setAccountChart(state, action)

        case actionTypes.SET_DEVICE_CHART:
            return setDeviceChart(state, action)

        case actionTypes.SET_PLAYLISTS_SORTING:
            return setPlaylistsSorting(state, action)

        case actionTypes.SET_GAMES_SORTING:
            return setGamesSorting(state, action)

        case actionTypes.SET_ACTIVE_DEVICE:
            return setActiveDevice(state, action)

        case actionTypes.SET_ACTIVE_REGION:
            return setActiveRegion(state, action)
        case actionTypes.SET_ACTIVE_OPERATIONS_DIRECTOR:
            return setActiveOperationsDirector(state, action)
        case actionTypes.SET_ACTIVE_COMMUNITY:
            return setActiveCommunity(state, action)

        case actionTypes.SET_ACTIVE_PLAYLIST:
            return setActivePlaylist(state, action)

        case actionTypes.SET_SKIP:
            return setSkip(state, action)

        default:
            return state;
    }
};

export default reducer;
