import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/index';

import { Redirect } from 'react-router-dom';
import { updateObject, checkFieldValidity } from '../../utils/utility';
import Dialog from '../../components/UI/Dialog/Dialog';

import ForgotPassword from './ForgotPassword/ForgotPassword';

// Custom UI
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import ErrorText from '../../components/UI/ErrorText/ErrorText';

// Material UI
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    authContainer: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '48px',
    },
    loginForm: {
        backgroundColor: '#fff',
        padding: '24px',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '375px',
    },
    formInputWrapper: {
        marginBottom: '24px',
    },
    inputAdornment: {
        color: theme.palette.common.gray3,
    },
    visibilityAdornment: {
        cursor: 'pointer !important',
    },
    rememberMe: {
        marginBottom: '32px',
        color: `${theme.palette.common.gray3}`,
    },
    checkbox: {
        '& svg': {
            color: `${theme.palette.common.gray3}`,
        },
    },
    formActionsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    forgotPass: {
        paddingLeft: '0px',
        marginLeft: '0px',
        color: `${theme.palette.common.blue1}`,
        '&:hover': {
            backgroundColor: 'inherit',
        },
    },
    submitButton: {
        background: `${theme.palette.common.blue1}`,
        '&:hover': {
            background: `${theme.palette.common.blue0}`,
        },
        '&:disabled': {
            background: `${theme.palette.common.gray5}`,
            cursor: 'not-allowed',
        },
    },
}));

const Auth = (props) => {
    // console.log(process.env)
    const classes = useStyles();

    // Auth form state
    const [authForm, setAuthForm] = useState({
        email: {
            value: '',
            valid: false,
            validation: {
                required: true,
                isEmail: true,
            },
        },
        password: {
            value: '',
            valid: false,
            validation: {
                required: true,
            },
        },
    });

    // Password visibility state
    const [isPassVisible, setPassVisibility] = useState(false);

    const handleClickShowPassword = () => {
        setPassVisibility(!isPassVisible);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Form validation
    const [formIsValid, setFormIsValid] = useState(false);

    // Checkbox (might move to Auth form state)
    const [checkboxState, setCheckboxState] = useState(true);

    const handleCheckboxChange = (event) => {
        setCheckboxState(event.target.checked);
    };

    // Forgot password Dialog
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);

        props.onForgotPassClose();
    };

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedFormElement = updateObject(authForm[inputIdentifier], {
            value: event.target.value,
            valid: checkFieldValidity(
                event.target.value,
                authForm[inputIdentifier].validation
            ),
        });

        const updatedAuthForm = updateObject(authForm, {
            [inputIdentifier]: updatedFormElement,
        });

        let formIsValid = true;

        for (let inputIdentifier in updatedAuthForm) {
            formIsValid = updatedAuthForm[inputIdentifier].valid && formIsValid;
        }

        setAuthForm(updatedAuthForm);
        setFormIsValid(formIsValid);
    };

    const submitHandler = (event) => {
        event.preventDefault();

        props.onAuth(authForm.email.value, authForm.password.value);
    };

    let errorMessage = null;

    if (props.error) {
        errorMessage = <ErrorText message={props.error} />;
    }

    let form = (
        <form className={classes.loginForm} onSubmit={submitHandler}>
            {/* Email Input */}
            <Input
                elementType="input"
                elementConfig={{
                    type: 'email',
                    placeholder: 'Email',
                    autoFocus: true,
                    className: classes.formInputWrapper,
                    id: 'email-input',
                    startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                            <EmailIcon />
                        </InputAdornment>
                    ),
                }}
                value={authForm.email.value}
                invalid={!authForm.email.valid}
                changed={(event) => {
                    inputChangedHandler(event, 'email');
                }}
            />

            {/* Password Input */}
            <Input
                elementType="input"
                elementConfig={{
                    type: isPassVisible ? 'text' : 'password',
                    placeholder: 'Password',
                    className: classes.formInputWrapper,
                    id: 'password-input',
                    startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                            <LockIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {isPassVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    ),
                }}
                value={authForm.password.value}
                invalid={!authForm.password.valid}
                changed={(event) => {
                    inputChangedHandler(event, 'password');
                }}
                autoFocus={false}
            />

            {/*{console.log('TRANSFER THIS TO Input.jsx AND MAKE CHEKBOX SUPPORT ')}*/}
            <FormControlLabel
                className={classes.rememberMe}
                label="Remember me"
                control={
                    <Checkbox
                        checked={checkboxState}
                        onChange={handleCheckboxChange}
                        name="remember-me-checkbox"
                        className={classes.checkbox}
                    />
                }
            />

            {errorMessage}

            <FormGroup className={classes.formActionsWrapper}>
                {!props.forgotPass && props.loading ? (
                    <Spinner size={40} />
                ) : (
                    <>
                        <Button
                            variant="text"
                            actionText={'Forgot password?'}
                            className={classes.forgotPass}
                            disableRipple={true}
                            clicked={handleDialogOpen}
                        />

                        <Button
                            variant="contained"
                            actionText={'Log in'}
                            className={classes.submitButton}
                            color={'primary'}
                            clicked={submitHandler}
                            disabled={!formIsValid}
                        />
                    </>
                )}
            </FormGroup>
        </form>
    );

    let authRedirect = null;

    if (props.isAuthenticated) {
        authRedirect = <Redirect to={props.authRedirectPath} />;
    }

    let forgotPassDialog = (
        <Dialog
            open={dialogOpen}
            handleClose={handleDialogClose}
            handleOpen={handleDialogOpen}
        >
            <ForgotPassword
                forgotPass={props.forgotPass}
                forgotPassSuccess={props.forgotPassSuccess}
                error={props.error}
                loading={props.loading}
                onSubmit={props.onForgotPass}
                onForgotPassClose={handleDialogClose}
            />
        </Dialog>
    );

    return (
        <div className={classes.authContainer}>
            {authRedirect}

            {form}

            {forgotPassDialog}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        forgotPass: state.auth.forgotPass,
        forgotPassSuccess: state.auth.forgotPassSuccess,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: (email, password) => dispatch(actions.auth(email, password)),
        onForgotPass: (email) => dispatch(actions.forgotPass(email)),
        onForgotPassClose: () => dispatch(actions.forgotPassFinish()),
        // onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);