import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {AppBar, Toolbar, IconButton} from '@material-ui/core';
import Logo from '../../../components/UI/Logo/Logo';
import AppBarItem from './AppBarItem/AppBarItem';

import {withRouter} from 'react-router';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    logo: {
        marginRight: 'auto',
        borderRadius: '16px',
    },
    menuItemsWrapper: {
        color: '#808893'
    }
}));

const menuItems = [
  {
    name: 'Logout',
    to: '/logout',
    isSubMenu: false,
  }
];

const DashboardAppBar = withRouter((props) => {
    const classes = useStyles();

    const {history} = props;

    const appBarItemClickHandler = (to) => {
        console.log(to);

        if (to[0] === '/') {
            history.push(to);
        }
    };

    const menuItemsButtons = menuItems.map((item) => {
        return (
            <AppBarItem
                key={item.name}
                item={item}
                onItemClick={appBarItemClickHandler}
            />
        )
    });

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar className='fancyShadow'>

                        <IconButton
                            edge="start"
                            className={classes.logo}
                            color="inherit"
                            aria-label="logo"
                            onClick={() => appBarItemClickHandler('/')}
                        >
                            <Logo/>
                        </IconButton>
                        <div className={`${classes.menuItemsWrapper} flex-sEvenly`}>
                            {history.location.pathname !== '/login' ? menuItemsButtons : null}
                        </div>

                </Toolbar>
            </AppBar>
        </div>
);
});

export default DashboardAppBar;
