import React from "react";
import Chart from "react-apexcharts";
import {useTheme} from "@material-ui/core/styles";

const Donutchart = ({data, labels, type = 'pie', showLegend = false, height = 165, offsetX = 0, width = 150}) => {
    const {infoBlue, infoPurple, infoRed, infoYellow} = useTheme().palette.common
    return (
        <Chart
            options={{
                colors: [infoBlue, infoPurple, infoRed, infoYellow],
                chart: {
                    offsetX: offsetX
                },
                legend: {
                    show: showLegend,
                    horizontalAlign: 'center',
                    position: "bottom"
                },
                responsive: [
                    {
                        breakpoint: 600,
                        options: {
                            chart: {
                                width: "100%",
                                height: height / 1.6
                            },
                            legend: {
                                show: true
                            }
                        }
                    },
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: "100%"
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ],
                labels
            }}
            width={width}
            height={height}
            series={data}
            type={type}
        />
    );
};

export default Donutchart;
