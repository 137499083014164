import React, { useState } from "react";
import TopPlayedGames from "../../components/UI/TopPlayedGames";
import { Modal } from "@material-ui/core";
import GameRanking from "../../components/UI/GameRanking";
import DonutChart from "../../components/Charts/DonutChart";
import LineArea from "../../components/Charts/LineArea";
import Score from "../../components/UI/Score/";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import DropdownFilter from "../../utils/dropdown.class";
import { dataForChart, mergeIdenticalGames } from "../../utils/utility";
import Loader from "../../components/UI/Loader";
import Error from "../../components/UI/Error";
import { calculateAnalytics } from "../../utils/utility";
import RangeColumnChart from "../../components/Charts/RangeColumnChart";

export const Playlist = ({
	activeRegion,
	activeCommunity,
	activeDevice,
	activePlaylist,
	devices,
	dateRange,
	gamesSorting,
	setGamesSorting,
	error,
	loading,
}) => {
	const playlist =
		devices[activeRegion][activeCommunity][activeDevice].playlists[
			activePlaylist
		];
	const { gamesTop, timePlayed } = playlist;
	const { engagement, movement, memory, distraction, omi } =
		calculateAnalytics(playlist);

	const merged = mergeIdenticalGames(gamesTop);
	const [modalOpen, setModalOpen] = useState(false);
	const handleModalOpen = () => {
		setModalOpen(true);
	};

	const handleModalClose = () => {
		merged.forEach((game) => (game.show = true));
		setModalOpen(false);
	};

	const gamesSortDropdown = new DropdownFilter({
		// filterNames: ['Top OMI score', 'Most played games', 'Increased OMI games'],
		filterNames: ["Top OMI score", "Most played games"],
		option: gamesSorting,
		setOption: setGamesSorting,
		sourceArray: merged,
	});

	if (loading) {
		return <Loader />;
	}
	if (error) {
		return <Error />;
	}

	return (
		<div className="playlist">
			<div className="width100">
				<div className="flex playlist__donut">
					<div>
						<DonutChart
							labels={[
								"Engagement",
								"Memory",
								"Distraction",
								"Movement",
							]}
							data={dataForChart([
								engagement,
								memory,
								distraction,
								movement,
							])}
							type={"pie"}
							showLegend={false}
							width={150}
							height={165}
						/>
					</div>

					<Score name="OMI" value={omi} />
					<Score name="Movement" value={movement} color="Yellow" />
					<Score name="Engagement" value={engagement} color="Blue" />
					<Score name="Memory" value={memory} color="Purple" />
					<Score name="Distraction" value={distraction} color="Red" />
				</div>
			</div>
			<div className="flex">
				<div className="mainWrapper">
					<div className="playlist__heatmap">
						{playlist.chartData.engagement.length > 1 ? (
							<LineArea
								series={playlist.chartData}
								height={400}
								width={800}
							/>
						) : (
							<p>
								More information will be displayed here once the
								playlist has more than one day sessions.
							</p>
						)}
					</div>
				</div>
				<div className="sideBarWrapper">
					<TopPlayedGames
						gamesTop={gamesSortDropdown.sortedArray()}
						openDialog={handleModalOpen}
						dropdown={gamesSortDropdown.render()}
					/>
				</div>
			</div>

			<div className="playlist__heatmap">
				<RangeColumnChart
					playlists={[playlist]}
					single={true}
					dateRange={dateRange}
					colors={[
						"#00bcd4",
						"#ff9800",
						"#ff5722",
						"#9c27b0",
						"#2196f3",
						"#009688",
						"#4caf50",
						"#8bc34a",
						"#ffeb3b",
						"#ffc107",
						"#795548",
						"#607d8b",
						"#e91e63",
						"#673ab7",
						"#3f51b5",
						"#2196f3",
						"#03a9f4",
					]}

					// colors={[
					// 	"#6F7A27",
					// 	"#DDD8CD",
					// 	"#597204",
					// 	"#4FB75B",
					// 	"#F31964",
					// 	"#B3DF87",
					// 	"#C34941",
					// 	"#223CC3",
					// 	"#971122",
					// 	"#3E8863",
					// 	"#74CBB2",
					// 	"#A7FD74",
					// 	"#8424CF",
					// 	"#6BBAFE",
					// 	"#FF6F75",
					// 	"#C1C357",
					// 	"#789D13",
					// 	"#DD87C7",
					// 	"#E12C6B",
					// 	"#526952",
					// 	"#85ABB0",
					// 	"#411E85",
					// 	"#F6FEEC",
					// 	"#D90FCE",
					// 	"#EC9445",
					// 	"#248C47",
					// 	"#B540FD",
					// 	"#0DC52E",
					// 	"#964710",
					// 	"#089F28",
					// 	"#CEF6D8",
					// 	"#BD1161",
					// 	"#F390F5",
					// 	"#530668",
					// 	"#B4EA34",
					// 	"#26679F",
					// 	"#671730",
					// 	"#DB55F8",
					// 	"#178639",
					// 	"#666853",
					// 	"#FA48B1",
					// 	"#033279",
					// 	"#BD164A",
					// 	"#5C8734",
					// 	"#CFDF41",
					// 	"#8CA367",
					// 	"#D0332A",
					// 	"#CAE385",
					// 	"#0C834B",
					// 	"#C94FFC",
					// 	"#AF42B3",
					// 	"#698A22",
					// 	"#E46410",
					// 	"#55618B",
					// 	"#BC8B8B",
					// 	"#EC5E9B",
					// 	"#B846B3",
					// 	"#E52D81",
					// 	"#E785D1",
					// 	"#2AE1F4",
					// 	"#FE19AD",
					// 	"#B15438",
					// 	"#E43503",
					// 	"#ED950F",
					// 	"#7CC1CC",
					// 	"#B79D6C",
					// 	"#A7D2AB",
					// 	"#05CE47",
					// 	"#4341E2",
					// 	"#99F551",
					// 	"#313957",
					// 	"#02C641",
					// 	"#D7CDA0",
					// 	"#5062DF",
					// 	"#34DA69",
					// 	"#6368C2",
					// 	"#E1762A",
					// 	"#5C51CA",
					// 	"#07A65C",
					// 	"#EE1283",
					// 	"#3CE751",
					// 	"#47376A",
					// 	"#6E86EA",
					// 	"#3566B2",
					// 	"#F3269B",
					// 	"#E436F0",
					// 	"#512B57",
					// 	"#43DD75",
					// 	"#D4F17D",
					// 	"#72D2C4",
					// 	"#0D7090",
					// 	"#93F50F",
					// 	"#DA966B",
					// 	"#705E83",
					// 	"#8FE79C",
					// 	"#CA1A4D",
					// 	"#214CE6",
					// 	"#AA6672",
					// 	"#5F5231",
					// 	"#53E372",
					// 	"#8B340B",
					// 	"#AC3DB3",
					// 	"#EC23DA",
					// 	"#A9CEB3",
					// 	"#1E1102",
					// 	"#25DF11",
					// 	"#08E739",
					// 	"#6A03A8",
					// 	"#DCF66A",
					// 	"#A25502",
					// 	"#8D9F67",
					// 	"#190AD8",
					// 	"#33E4CB",
					// 	"#31E22C",
					// 	"#1AFC50",
					// 	"#54157C",
					// 	"#CB06E7",
					// 	"#AA3D28",
					// 	"#C11F8D",
					// 	"#5C48C5",
					// 	"#0DEF30",
					// 	"#313ED6",
					// 	"#4D1A9B",
					// 	"#9BBE05",
					// 	"#C05849",
					// 	"#6B981E",
					// 	"#56DA86",
					// 	"#BBF22E",
					// 	"#FFE600",
					// 	"#E871F5",
					// 	"#89EA73",
					// 	"#8EE235",
					// 	"#CCF2EF",
					// 	"#9456B4",
					// 	"#8342A8",
					// 	"#BF6937",
					// 	"#C3B4B4",
					// 	"#097108",
					// 	"#8D5AE4",
					// 	"#D71A3E",
					// 	"#65572C",
					// 	"#41596A",
					// 	"#12A459",
					// 	"#8A13BD",
					// 	"#B0E200",
					// 	"#2ABE50",
					// 	"#EBF684",
					// 	"#00CE9C",
					// 	"#1A53E5",
					// 	"#7A407E",
					// 	"#D77F1C",
					// 	"#9B9F70",
					// 	"#FD20C2",
					// 	"#57AE20",
					// 	"#B0F6E6",
					// 	"#577BCB",
					// 	"#E6F5F1",
					// 	"#B08F10",
					// 	"#F97FCB",
					// 	"#36D7B8",
					// 	"#BF6230",
					// 	"#832593",
					// 	"#B85400",
					// 	"#3A7292",
					// 	"#18793C",
					// 	"#ADAC3D",
					// 	"#753679",
					// 	"#B1F15C",
					// 	"#1AD66A",
					// 	"#E49433",
					// 	"#FB5860",
					// 	"#C8E5FD",
					// 	"#80CF31",
					// 	"#BC6B73",
					// 	"#538EF5",
					// 	"#999A9D",
					// 	"#AC2AC1",
					// 	"#643D57",
					// 	"#1F923B",
					// 	"#11DB9A",
					// 	"#4DF454",
					// 	"#C07720",
					// 	"#90048B",
					// 	"#E17955",
					// 	"#CE0325",
					// 	"#0F173E",
					// 	"#4597A1",
					// 	"#A55058",
					// 	"#345AEB",
					// 	"#1BD767",
					// 	"#F47558",
					// 	"#D0C29D",
					// 	"#9D0326",
					// 	"#A91DDC",
					// 	"#D33841",
					// 	"#993ED1",
					// 	"#EDD7BD",
					// 	"#284D09",
					// 	"#08A97C",
					// 	"#2B64C7",
					// 	"#68DED5",
					// 	"#91C99A",
					// 	"#49A258",
					// 	"#E29589",
					// 	"#F9F0DD",
					// 	"#CB6C85",
					// 	"#C67EBA",
					// 	"#4005DA",
					// 	"#93B209",
					// 	"#363E96",
					// 	"#7793A0",
					// 	"#A7BF70",
					// 	"#7030E5",
					// 	"#7FC55F",
					// 	"#BB876B",
					// 	"#2FA0B8",
					// 	"#C9B09A",
					// 	"#9320D6",
					// 	"#F53CF7",
					// 	"#82B577",
					// 	"#0EF42A",
					// 	"#640DC3",
					// 	"#33663F",
					// 	"#B3AF17",
					// 	"#CB50EB",
					// 	"#FBDDB0",
					// 	"#5BF64C",
					// 	"#EA757D",
					// 	"#7EBEE0",
					// 	"#022603",
					// 	"#D13F58",
					// 	"#37E61F",
					// 	"#032F8E",
					// 	"#C7E04A",
					// 	"#FCEF6B",
					// 	"#4976AA",
					// 	"#F5324E",
					// 	"#2081F0",
					// 	"#C84DFD",
					// 	"#A44EAC",
					// 	"#C94BCE",
					// 	"#9822D8",
					// 	"#99EA44",
					// 	"#8333BA",
					// 	"#7923B1",
					// 	"#75F67B",
					// 	"#A5AC68",
					// 	"#F7007E",
					// 	"#A11DFA",
					// 	"#9646C0",
					// 	"#A25B1B",
					// 	"#417087",
					// 	"#15684E",
					// 	"#827679",
					// 	"#ADE161",
					// 	"#B9AD17",
					// 	"#C2323C",
					// 	"#46B444",
					// 	"#666121",
					// 	"#3AB0AE",
					// 	"#0BE3AE",
					// 	"#71511B",
					// 	"#DD41AB",
					// 	"#DC2D7B",
					// 	"#805A45",
					// 	"#1D7FA3",
					// 	"#54ECE0",
					// 	"#158EC0",
					// 	"#76256A",
					// 	"#59A714",
					// 	"#3F0A6F",
					// 	"#712C2F",
					// 	"#3F03DD",
					// 	"#F8AD65",
					// 	"#398B57",
					// 	"#5547B9",
					// 	"#3CCF71",
					// 	"#D7F5DF",
					// 	"#3C921A",
					// 	"#E303E2",
					// 	"#9470AB",
					// 	"#98ED96",
					// 	"#314F1A",
					// 	"#14BEE1",
					// 	"#8329B1",
					// 	"#933D31",
					// 	"#061FF1",
					// 	"#42F174",
					// 	"#3FD825",
					// 	"#65C3A3",
					// 	"#DBD7CF",
					// 	"#A4B318",
					// 	"#33A019",
					// 	"#DC27E2",
					// 	"#D0A06C",
					// 	"#444AA5",
					// 	"#AA06CC",
					// 	"#D84C93",
					// 	"#A0834E",
					// 	"#17D09E",
					// 	"#349B56",
					// 	"#3D39C1",
					// 	"#E7C3B9",
					// 	"#1FEA27",
					// 	"#622E76",
					// 	"#0B1C96",
					// 	"#BE567C",
					// 	"#9D7239",
					// 	"#E3B789",
					// 	"#1FEBD6",
					// 	"#9AE2BD",
					// 	"#C62E88",
					// 	"#56D093",
					// 	"#EC4917",
					// 	"#F408FE",
					// 	"#87A734",
					// 	"#78EAB0",
					// 	"#C81F0E",
					// 	"#25DCAE",
					// 	"#7A1EBD",
					// 	"#FFA955",
					// 	"#571196",
					// 	"#26153E",
					// 	"#D6D5DA",
					// 	"#1632AF",
					// 	"#A85D66",
					// 	"#7ED171",
					// 	"#0B3428",
					// 	"#F5D10C",
					// 	"#0AB7E2",
					// 	"#1DFAEE",
					// 	"#07700F",
					// 	"#257AD9",
					// 	"#38BEC2",
					// 	"#C1F75C",
					// 	"#5BE622",
					// 	"#9102B5",
					// 	"#BB04B2",
					// 	"#A7E7CF",
					// 	"#E1A3A2",
					// 	"#D5E680",
					// 	"#539B76",
					// 	"#9C1489",
					// 	"#940579",
					// 	"#2DA40F",
					// 	"#6CC720",
					// 	"#4CA5E8",
					// 	"#6255E5",
					// 	"#EA2329",
					// 	"#448B2C",
					// 	"#8AF6B7",
					// 	"#AB2EDF",
					// 	"#BA7CFA",
					// 	"#6B3DAA",
					// 	"#03276C",
					// 	"#A89E30",
					// 	"#DA1467",
					// 	"#3BF140",
					// 	"#5083F8",
					// 	"#EEC935",
					// 	"#6E4FD9",
					// 	"#3DDCF0",
					// 	"#E61AF2",
					// 	"#650EC2",
					// 	"#1586CE",
					// 	"#83BE01",
					// 	"#72AA88",
					// 	"#112E2E",
					// 	"#C35E82",
					// 	"#AD06C4",
					// 	"#098B32",
					// 	"#92E982",
					// 	"#97A8EB",
					// 	"#35C751",
					// 	"#533253",
					// 	"#1FB321",
					// 	"#C9DE87",
					// 	"#881554",
					// 	"#481220",
					// 	"#4E815F",
					// 	"#6DF4D9",
					// 	"#8A017C",
					// 	"#DC865E",
					// 	"#762480",
					// 	"#AB303D",
					// 	"#429CE3",
					// 	"#80B3A2",
					// 	"#731284",
					// 	"#17375C",
					// 	"#30F9CA",
					// 	"#043BD0",
					// 	"#5BFF4B",
					// 	"#2408BE",
					// 	"#A8AD8A",
					// 	"#3F83EC",
					// 	"#ACA93A",
					// 	"#6B5D7F",
					// 	"#9C5F6C",
					// 	"#2F0E5C",
					// 	"#9C45AE",
					// 	"#252513",
					// 	"#A687A3",
					// 	"#109B12",
					// 	"#D23F3C",
					// 	"#D5F9B3",
					// 	"#8078FF",
					// 	"#823217",
					// 	"#582981",
					// 	"#0AE73F",
					// 	"#FD4FDC",
					// 	"#6ED905",
					// 	"#EA3DB3",
					// 	"#1891F5",
					// 	"#E99439",
					// 	"#104E9B",
					// 	"#E4542A",
					// 	"#C7E7AE",
					// 	"#6F86FB",
					// 	"#C036AD",
					// 	"#0C399F",
					// 	"#E32413",
					// 	"#D3B54F",
					// 	"#534AE2",
					// 	"#E8EB74",
					// 	"#6188DC",
					// 	"#C04F98",
					// 	"#2495C9",
					// 	"#F62934",
					// 	"#C8D3AF",
					// 	"#17C7DA",
					// 	"#07F12F",
					// 	"#E1F243",
					// 	"#72110E",
					// 	"#5A4ED1",
					// 	"#6DF4F3",
					// 	"#93EB3A",
					// 	"#9EAB8B",
					// 	"#FA610F",
					// 	"#2BEF52",
					// 	"#A337B6",
					// 	"#966176",
					// 	"#A216F3",
					// 	"#DEE2F4",
					// 	"#7783E8",
					// 	"#DC6DD3",
					// 	"#9DEE72",
					// 	"#78C9B1",
					// 	"#F85583",
					// 	"#1C8538",
					// 	"#038C70",
					// 	"#0ED290",
					// 	"#A49ADD",
					// 	"#6A426C",
					// 	"#6EDB6B",
					// 	"#2EC827",
					// 	"#261524",
					// 	"#373619",
					// 	"#19E1A8",
					// 	"#CC33D7",
					// 	"#344369",
					// 	"#41A15C",
					// 	"#5103F3",
					// 	"#A90972",
					// 	"#0E9945",
					// 	"#10F2B0",
					// 	"#193C54",
					// 	"#60BA57",
					// 	"#87E38D",
					// 	"#F3BCAD",
					// 	"#C6A616",
					// 	"#1932FB",
					// 	"#3F1B41",
					// 	"#D2ADAA",
					// 	"#BE2852",
					// 	"#F1BC2B",
					// 	"#D73385",
					// 	"#6C6A2D",
					// 	"#84ECA1",
					// 	"#1DE590",
					// 	"#8BC9A9",
					// 	"#3E09C2",
					// 	"#9EA7C7",
					// 	"#6451B1",
					// 	"#B29E35",
					// 	"#7DAFBB",
					// 	"#62A72F",
					// 	"#8E2079",
					// 	"#17FC99",
					// 	"#4723B3",
					// 	"#A4BD32",
					// 	"#3BE79E",
					// 	"#AD8982",
					// 	"#7925F7",
					// 	"#F57B64",
					// 	"#4D72B9",
					// 	"#CCF558",
					// 	"#8053AB",
					// 	"#6EA7D2",
					// 	"#ECBAD5",
					// 	"#B8F007",
					// 	"#B6C39E",
					// 	"#5261D3",
					// 	"#E1D6B0",
					// 	"#03986A",
					// 	"#5D39D7",
					// 	"#CD4CF0",
					// 	"#736BFB",
					// 	"#B621E9",
					// 	"#57048F",
					// 	"#69511D",
					// 	"#CFA2DA",
					// 	"#474604",
					// 	"#92D968",
					// 	"#4A008B",
					// 	"#74BF5E",
					// 	"#51C1D0",
					// 	"#200FA2",
					// 	"#443BCC",
					// 	"#61B327",
					// 	"#AD3335",
					// 	"#8B10AA",
					// 	"#D46146",
					// 	"#E49540",
					// 	"#61A612",
					// 	"#C6F414",
					// 	"#22B33B",
					// 	"#9EA7D9",
					// 	"#B439E3",
					// 	"#F02718",
					// 	"#1BCD2E",
					// 	"#1BE661",
					// 	"#6B9D46",
					// 	"#FB1A3D",
					// 	"#4B3288",
					// 	"#EB4CFD",
					// 	"#A22F01",
					// 	"#A850B4",
					// 	"#8C078F",
					// 	"#31A395",
					// 	"#F136D5",
					// 	"#A07B7E",
					// 	"#D681DE",
					// 	"#7C8089",
					// 	"#6709ED",
					// 	"#E69E85",
					// 	"#EA5588",
					// 	"#93A17E",
					// 	"#75DFAE",
					// 	"#FD92F9",
					// 	"#985237",
					// 	"#7044FD",
					// 	"#00D279",
					// 	"#1AE771",
					// 	"#C17935",
					// 	"#96569B",
					// 	"#F3C916",
					// 	"#6A5324",
					// 	"#5014BC",
					// 	"#58C494",
					// 	"#563891",
					// 	"#DED010",
					// 	"#0CB836",
					// 	"#E32492",
					// 	"#C5F41D",
					// 	"#DC5D5D",
					// 	"#CC4909",
					// 	"#7A0C71",
					// 	"#F3216C",
					// 	"#79F177",
					// 	"#475DAA",
					// 	"#5AB501",
					// 	"#20C7C2",
					// 	"#1E1E53",
					// 	"#DF95BD",
					// 	"#D0B026",
					// 	"#653FB6",
					// 	"#44C5B4",
					// 	"#939896",
					// 	"#8E0487",
					// 	"#A0F8BE",
					// 	"#2BD4C1",
					// 	"#536E24",
					// 	"#4CF809",
					// 	"#D8058B",
					// 	"#33F0AA",
					// 	"#673B71",
					// 	"#538629",
					// 	"#6009B1",
					// 	"#52F1FF",
					// 	"#43568E",
					// 	"#7813F1",
					// 	"#611BE7",
					// 	"#1591BE",
					// 	"#8C8AFB",
					// 	"#43AB42",
					// 	"#D0F27C",
					// 	"#5B8424",
					// 	"#FC840D",
					// 	"#E4B8A5",
					// 	"#040C78",
					// 	"#8099A1",
					// 	"#F299A0",
					// 	"#92F28A",
					// 	"#A51E63",
					// 	"#2D2DEE",
					// 	"#E361EC",
					// 	"#157A94",
					// 	"#4D4CBA",
					// 	"#C9D730",
					// 	"#C6B7A4",
					// 	"#120FEC",
					// 	"#17880B",
					// 	"#72F030",
					// 	"#A15480",
					// 	"#5426E9",
					// 	"#D13C74",
					// 	"#E62BB9",
					// 	"#528C80",
					// 	"#D1158F",
					// 	"#2B8EF2",
					// 	"#7BCC07",
					// 	"#7C77C8",
					// 	"#0CDF74",
					// 	"#54CB37",
					// 	"#EDF364",
					// 	"#5DFA7E",
					// 	"#FAB817",
					// 	"#9FE315",
					// 	"#A88B06",
					// 	"#C1D533",
					// 	"#B74654",
					// 	"#8AD07F",
					// 	"#D4C3CC",
					// 	"#BB7B3C",
					// 	"#223D4E",
					// 	"#608CE9",
					// 	"#75AA19",
					// 	"#8B913E",
					// 	"#DF66A8",
					// 	"#962E70",
					// 	"#29C212",
					// 	"#ED636B",
					// 	"#2FBF4B",
					// 	"#8F5B77",
					// 	"#27CFEA",
					// 	"#D70CEC",
					// 	"#8A2F2E",
					// 	"#650618",
					// 	"#73483D",
					// 	"#67F8B3",
					// 	"#413D62",
					// 	"#DB067B",
					// 	"#F68D6B",
					// 	"#517E3D",
					// 	"#0C0AA4",
					// 	"#FC5126",
					// 	"#5EF4EE",
					// 	"#E5DED5",
					// 	"#6B6147",
					// 	"#82FEF3",
					// 	"#93B7BB",
					// 	"#A975D2",
					// 	"#1D0E04",
					// 	"#AD13A9",
					// 	"#743D0F",
					// 	"#F44A4F",
					// 	"#1B6E33",
					// 	"#4590E3",
					// 	"#17EF88",
					// 	"#CCFCA9",
					// 	"#FF9713",
					// 	"#D8966C",
					// 	"#4A89CB",
					// 	"#075392",
					// 	"#164949",
					// 	"#34371A",
					// 	"#11671D",
					// 	"#E1FB14",
					// 	"#82AAF1",
					// 	"#5E76F7",
					// 	"#E096C0",
					// 	"#1C2011",
					// 	"#7292AF",
					// 	"#960A67",
					// 	"#1A7FE5",
					// 	"#FDE9D2",
					// 	"#F398BF",
					// 	"#4963CA",
					// 	"#120A5D",
					// 	"#DDD790",
					// 	"#1D4F2A",
					// 	"#8A76AC",
					// 	"#CC3421",
					// 	"#7525F6",
					// 	"#9DEAD3",
					// 	"#600582",
					// 	"#763420",
					// 	"#61FD83",
					// 	"#2DACD5",
					// 	"#A42144",
					// 	"#89B39A",
					// 	"#9C9DD7",
					// 	"#6ED081",
					// 	"#77A359",
					// 	"#FD2205",
					// 	"#F3B185",
					// 	"#037DC3",
					// 	"#E55336",
					// 	"#AEB49E",
					// 	"#7DC901",
					// 	"#56E237",
					// 	"#D514C6",
					// 	"#B846AC",
					// 	"#70F4C2",
					// 	"#53A7BA",
					// 	"#A2A192",
					// 	"#56A820",
					// 	"#AC4548",
					// 	"#9C4CC6",
					// 	"#4F95CB",
					// 	"#285564",
					// 	"#40BDA8",
					// 	"#E7085D",
					// 	"#29A729",
					// 	"#041A6B",
					// 	"#FC4380",
					// 	"#50919F",
					// 	"#CDE8E3",
					// 	"#A312AD",
					// 	"#89D6ED",
					// 	"#0D661B",
					// 	"#EB71B1",
					// 	"#1C6BFC",
					// 	"#776D70",
					// 	"#166574",
					// 	"#75C966",
					// 	"#4F5257",
					// 	"#0CA942",
					// 	"#7BE7C8",
					// 	"#B4E7CE",
					// 	"#224C86",
					// 	"#BDC5BA",
					// 	"#ED300D",
					// 	"#4B6B0C",
					// 	"#8C3E58",
					// 	"#E9B6D2",
					// 	"#FE8B53",
					// 	"#230C4D",
					// 	"#3B686E",
					// 	"#85F3EB",
					// 	"#F1884D",
					// 	"#2DA6A2",
					// 	"#2E3560",
					// 	"#D65415",
					// 	"#EB82DA",
					// 	"#8E0471",
					// 	"#B31F42",
					// 	"#46232B",
					// 	"#945DA2",
					// 	"#153CF3",
					// 	"#4B5D55",
					// 	"#392B8F",
					// 	"#C8F63A",
					// 	"#23A4B9",
					// 	"#BF1268",
					// 	"#E42FFD",
					// 	"#A9CA9D",
					// 	"#72B08E",
					// 	"#2E18BD",
					// 	"#596E93",
					// 	"#60B254",
					// 	"#EDD534",
					// 	"#8872CA",
					// 	"#60923C",
					// 	"#FFAB5D",
					// 	"#A17E43",
					// 	"#25958A",
					// 	"#15FA86",
					// 	"#132FAC",
					// 	"#1B57BE",
					// 	"#157FF2",
					// 	"#42620C",
					// 	"#C0AE66",
					// 	"#E8E54B",
					// 	"#DD5D59",
					// 	"#1E86CA",
					// 	"#522296",
					// 	"#5F9C16",
					// 	"#53A7E8",
					// 	"#0E1FF3",
					// 	"#3A897D",
					// 	"#B1C611",
					// 	"#D058D6",
					// 	"#17BDBD",
					// 	"#EA885F",
					// 	"#A21C8E",
					// 	"#0E219C",
					// 	"#552A7D",
					// 	"#0E8F10",
					// 	"#32D84D",
					// 	"#3DCC77",
					// 	"#CD3E4F",
					// 	"#058B69",
					// 	"#D3CC84",
					// 	"#1E566B",
					// 	"#92AC00",
					// 	"#7A8C0C",
					// 	"#43887B",
					// 	"#312202",
					// 	"#E1F3A2",
					// 	"#1D03CF",
					// 	"#35F1B6",
					// 	"#75D8BF",
					// 	"#E38309",
					// 	"#320A20",
					// 	"#72E820",
					// 	"#F4FC6A",
					// 	"#22EBEF",
					// 	"#A34160",
					// 	"#1B687B",
					// 	"#2DC955",
					// 	"#17E3EA",
					// 	"#B342B9",
					// 	"#68EDAC",
					// 	"#3B25A2",
					// 	"#53CADD",
					// 	"#741F33",
					// 	"#E93469",
					// 	"#E57877",
					// 	"#ADE180",
					// 	"#77BB25",
					// 	"#2E987D",
					// 	"#5FD731",
					// 	"#9EDBA2",
					// 	"#CEE64E",
					// 	"#2218CE",
					// 	"#9443C7",
					// 	"#CA5078",
					// 	"#199325",
					// 	"#05C77D",
					// 	"#EC5AEE",
					// 	"#A13FCD",
					// 	"#D90CE7",
					// 	"#1EC266",
					// 	"#661E07",
					// 	"#C07363",
					// 	"#5483FF",
					// 	"#573EEF",
					// 	"#6AEE9D",
					// 	"#264688",
					// 	"#85A883",
					// 	"#BA1B74",
					// 	"#F7B95C",
					// 	"#E9FD2B",
					// 	"#0CA263",
					// 	"#F0DEF2",
					// 	"#776317",
					// 	"#B80436",
					// 	"#8883BE",
					// 	"#87B189",
					// 	"#10957B",
					// 	"#33B3A7",
					// 	"#9B5182",
					// 	"#5101A4",
					// 	"#AE76CD",
					// 	"#D7B0A9",
					// 	"#8B899E",
					// 	"#D18C25",
					// 	"#DA531A",
					// 	"#A5C84B",
					// 	"#DE079B",
					// 	"#B5C848",
					// 	"#BF1F76",
					// 	"#D33C75",
					// 	"#405648",
					// 	"#64B6BF",
					// 	"#A85CB2",
					// 	"#D02B68",
					// 	"#5E43AB",
					// 	"#C23D0C",
					// 	"#1CDB5E",
					// 	"#17DA5B",
					// 	"#9CACAC",
					// 	"#3C63C2",
					// 	"#42497E",
					// 	"#9C4B47",
					// 	"#FC99C6",
					// 	"#B0642A",
					// 	"#4DD97B",
					// 	"#780ECD",
					// 	"#85A3E1",
					// 	"#4A4D91",
					// 	"#237F0B",
					// 	"#F764D2",
					// 	"#1394B1",
					// 	"#1566D0",
					// 	"#3BC911",
					// 	"#FAA11B",
					// 	"#2F777C",
					// 	"#40E432",
					// 	"#5F2293",
					// 	"#01F0E2",
					// 	"#1713C6",
					// 	"#C8C360",
					// 	"#EE0D55",
					// 	"#781290",
					// 	"#3767CA",
					// 	"#9774AC",
					// 	"#068EF9",
					// 	"#031789",
					// 	"#2F520D",
					// 	"#88D296",
					// 	"#02E066",
					// 	"#DD21B0",
					// 	"#A0EFB7",
					// 	"#D04503",
					// 	"#CD3952",
					// 	"#4EA7DA",
					// 	"#54DDF8",
					// 	"#2C7AE4",
					// 	"#9FEBEB",
					// 	"#DB0BD3",
					// 	"#0751A0",
					// 	"#E43FC3",
					// 	"#4D4F4A",
					// 	"#1DFC8C",
					// 	"#58BB82",
					// 	"#CA91DB",
					// 	"#57E894",
					// 	"#3CD163",
					// 	"#5B8EAE",
					// 	"#8575B7",
					// 	"#0350B4",
					// 	"#1DD6BF",
					// 	"#3FA552",
					// 	"#FC3B6A",
					// 	"#1184C5",
					// 	"#5AE389",
					// 	"#777C89",
					// 	"#41CA9E",
					// 	"#04CB4B",
					// 	"#26F1FF",
					// 	"#FB63B6",
					// 	"#C6D7F8",
					// 	"#787046",
					// 	"#59812D",
					// 	"#2EC419",
					// 	"#DF14E4",
					// 	"#D515B1",
					// 	"#5B4928",
					// 	"#02CBB9",
					// 	"#57791B",
					// 	"#0A015C",
					// 	"#EAC824",
					// 	"#323E07",
					// 	"#834EB4",
					// 	"#1BEEED",
					// 	"#89CE6E",
					// 	"#68ECA3",
					// 	"#F5655B",
					// 	"#319A9B",
					// 	"#8BE688",
					// 	"#7BC3EA",
					// 	"#AECA5D",
					// 	"#955EFE",
					// 	"#E8E4D5",
					// 	"#4B9CFD",
					// 	"#379C81",
					// 	"#942994",
					// 	"#11AC0C",
					// 	"#263653",
					// 	"#831DF4",
					// 	"#B047EB",
					// 	"#B7259F",
					// 	"#4BE4D5",
					// 	"#8018E5",
					// 	"#67B2D8",
					// 	"#E5A63D",
					// 	"#14FA7B",
					// 	"#2D6A49",
					// 	"#B2E929",
					// 	"#0F75C4",
					// 	"#F1EEBD",
					// 	"#D501B2",
					// 	"#C206CA",
					// 	"#921E33",
					// 	"#276C96",
					// 	"#1857C7",
					// 	"#A086BD",
					// 	"#CB9C7D",
					// 	"#B2C6CB",
					// 	"#1AC3AE",
					// 	"#AC737E",
					// 	"#B208B0",
					// 	"#94230D",
					// 	"#D6FDF7",
					// 	"#14FE2D",
					// 	"#8B94D9",
					// 	"#37964F",
					// 	"#40572B",
					// 	"#008334",
					// 	"#FDB94D",
					// 	"#860400",
					// 	"#D9CAD5",
					// 	"#B0B26C",
					// 	"#A61663",
					// 	"#3D5200",
					// 	"#0E5014",
					// 	"#34C95E",
					// 	"#34F814",
					// 	"#205093",
					// 	"#4493DA",
					// 	"#6EB37F",
					// 	"#997D6B",
					// 	"#BC6530",
					// 	"#68E022",
					// 	"#E5F7FF",
					// 	"#229D62",
					// 	"#5A98A7",
					// 	"#0CCC23",
					// 	"#18BE20",
					// 	"#061805",
					// 	"#803A15",
					// 	"#DE1F0C",
					// 	"#4BE4FE",
					// 	"#EB0505",
					// 	"#DAB4C1",
					// 	"#27476B",
					// 	"#D49492",
					// 	"#69E0D9",
					// 	"#1E8DAD",
					// 	"#453A13",
					// 	"#352950",
					// 	"#D90B3E",
					// 	"#34D0EA",
					// 	"#432637",
					// 	"#9A862E",
					// 	"#386558",
					// 	"#327629",
					// 	"#5B69E1",
					// 	"#B479B8",
					// 	"#362F75",
					// 	"#2AD0F4",
					// 	"#B14FEC",
					// 	"#0BAC69",
					// 	"#221016",
					// 	"#E62D34",
					// 	"#5B64B5",
					// 	"#C522C6",
					// 	"#691BD6",
					// 	"#01D9BF",
					// 	"#C7FC25",
					// 	"#AEDA3B",
					// 	"#0CA91A",
					// 	"#613954",
					// 	"#E1070B",
					// 	"#A957C3",
					// 	"#7CB779",
					// 	"#06A74A",
					// 	"#B2C27A",
					// 	"#066C40",
					// 	"#BCA5F8",
					// 	"#5CCAC4",
					// 	"#0C7CA7",
					// 	"#A13D8A",
					// 	"#485236",
					// 	"#384E7A",
					// 	"#3F1A8D",
					// 	"#098474",
					// 	"#E32DBF",
					// 	"#A1E31E",
					// 	"#D513C0",
					// 	"#E35658",
					// 	"#91C70B",
					// 	"#7349E8",
					// 	"#181EA4",
					// 	"#0D75ED",
					// 	"#06C15D",
					// 	"#0C48B8",
					// 	"#A91398",
					// 	"#D7F3B9",
					// 	"#FD9238",
					// 	"#015DAE",
					// 	"#D07D16",
					// 	"#8FCA92",
					// 	"#83F722",
					// 	"#1025A7",
					// 	"#86B4B4",
					// 	"#3D7E80",
					// 	"#53CF05",
					// 	"#7FF420",
					// 	"#91942A",
					// 	"#FF1F03",
					// 	"#2DC2A6",
					// 	"#EB5AB8",
					// 	"#4A0E6D",
					// 	"#CF04D3",
					// 	"#2C6A5A",
					// 	"#7631BF",
					// 	"#9CB06A",
					// 	"#913317",
					// 	"#7DE553",
					// 	"#1B1014",
					// 	"#D94403",
					// 	"#88AB10",
					// 	"#91FCD0",
					// 	"#02D9A6",
					// 	"#3DFE4A",
					// 	"#9D1E66",
					// 	"#73AC7F",
					// 	"#5814AA",
					// 	"#7AC3C5",
					// 	"#5D7274",
					// 	"#62B572",
					// 	"#9515BD",
					// 	"#628580",
					// 	"#360AF3",
					// 	"#0634F9",
					// 	"#9F2CC0",
					// 	"#1CEE78",
					// 	"#A47DB8",
					// 	"#949F44",
					// 	"#C8BC98",
					// 	"#C2E068",
					// 	"#22CF6D",
					// 	"#D823DD",
					// 	"#6BD6BF",
					// 	"#812D4E",
					// 	"#82527B",
					// 	"#AADFEE",
					// 	"#6710B5",
					// 	"#0DD438",
					// 	"#E9CFB7",
					// 	"#47AFFC",
					// 	"#4699E1",
					// 	"#3B34E6",
					// 	"#27A801",
					// 	"#94447B",
					// 	"#15A58E",
					// 	"#2538A4",
					// 	"#C5A94C",
					// 	"#BD4F67",
					// 	"#215CEE",
					// 	"#EB512C",
					// 	"#80D6EB",
					// 	"#4D145A",
					// 	"#189A06",
					// 	"#352FC3",
					// 	"#14B29B",
					// 	"#447382",
					// 	"#2662A5",
					// 	"#0F5307",
					// 	"#F4D9E9",
					// 	"#164AD3",
					// 	"#DC4AE7",
					// 	"#280461",
					// 	"#EC7CFF",
					// 	"#02928F",
					// 	"#4E6040",
					// 	"#5F9EB4",
					// 	"#AB0D01",
					// 	"#031AC8",
					// 	"#88EFC0",
					// 	"#090947",
					// 	"#F24DF1",
					// 	"#1900FB",
					// 	"#F96040",
					// 	"#C80B40",
					// 	"#E2FB87",
					// 	"#9F51F3",
					// 	"#235997",
					// 	"#90A9BA",
					// 	"#ADBF9C",
					// 	"#D02840",
					// 	"#F74C7A",
					// 	"#617BF7",
					// 	"#7E127E",
					// 	"#9E72C1",
					// 	"#7A24E9",
					// 	"#059C17",
					// 	"#BBCEB5",
					// 	"#66CC66",
					// 	"#431C12",
					// 	"#58A2BD",
					// 	"#34B55B",
					// 	"#03456A",
					// 	"#6D6CF9",
					// 	"#C5BB54",
					// 	"#F1C8B7",
					// 	"#FF64C1",
					// 	"#0AB581",
					// 	"#B40754",
					// 	"#5F083E",
					// 	"#52BA93",
					// 	"#E9B529",
					// 	"#8C0C67",
					// 	"#68C0F3",
					// 	"#22F9FF",
					// 	"#760B2C",
					// 	"#1BADC9",
					// 	"#777A3C",
					// 	"#D71A93",
					// 	"#8013C8",
					// 	"#B454B9",
					// 	"#B12973",
					// 	"#F69E39",
					// 	"#7CFCA7",
					// 	"#D84F46",
					// 	"#4727E5",
					// 	"#04E399",
					// 	"#2189F4",
					// 	"#AE1573",
					// 	"#CC3D4D",
					// 	"#70A68D",
					// 	"#6DC4FD",
					// 	"#4044AD",
					// 	"#6DED41",
					// 	"#F255AD",
					// 	"#584205",
					// 	"#A24789",
					// 	"#5DE38D",
					// 	"#511178",
					// 	"#0CB63F",
					// 	"#7DA333",
					// 	"#A582AF",
					// 	"#998FCF",
					// 	"#BB7E00",
					// 	"#180615",
					// ]}
				/>
			</div>

			<Modal open={modalOpen} onClose={handleModalClose}>
				<div className="modalWrapper flex">
					<GameRanking
						gamesArr={gamesSortDropdown.sortedArray()}
						dropdown={gamesSortDropdown.render()}
						dateRange={dateRange}
					/>
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	dateRange: state.analytics.dateRange,
	activePlaylist: state.analytics.activePlaylist,
	gamesSorting: state.analytics.gamesSorting,
	activeRegion: state.analytics.activeRegion,
	activeCommunity: state.analytics.activeCommunity,
	activeDevice: state.analytics.activeDevice,
	activePlaylist: state.analytics.activePlaylist,
	devices: state.analytics.devices,
	error: state.analytics.error,
	loading: state.analytics.loading,
});

export default connect(mapStateToProps, actions)(Playlist);
