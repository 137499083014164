import React, {useEffect} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
//import { getData } from '../redux/actions';
import * as actions from '../redux/actions/index'
import {connect} from 'react-redux'
import Region from '../containers/Region'
import Community from '../containers/Community'
import OperationsDirector from '../containers/OperationsDirector';
import Account from '../containers/Account';
import Multiplier from '../containers/Multiplier';
import Device from '../containers/Device';
import Playlist from '../containers/Playlist';
import Auth from '../containers/Auth/Auth';
import Logout from '../containers/Auth/Logout/Logout';

// import NotFound from '../layout/NotFound';
import PrivateRoute from './PrivateRoute.js';

const Routes = ({dateRange, isAuthenticated, getData, setSkip}) => {
    useEffect(() => {
        (async () => {
            if(typeof getData == 'function') {
                await getData(dateRange)
                setSkip(true);
            }
        })()
    }, [dateRange, isAuthenticated, getData]) 

    let routes = (
        <Switch>
            <Route exact path="/login" component={Auth}/>

            {/* Protected Routes */}
            <PrivateRoute exact path="/" component={Region}/>
            <PrivateRoute exact path="/logout" component={Logout}/>
            <PrivateRoute exact path="/multiplier" component={Multiplier}/>
            <PrivateRoute exact path="/:region" component={OperationsDirector}/>
            <PrivateRoute exact path="/:region/:operations_director" component={Community}/>
            <PrivateRoute exact path="/:region/:operations_director/:community" component={Account}/>
            <PrivateRoute exact path="/:region/:operations_director/:community/:device" component={Device}/>
            <PrivateRoute exact path="/:region/:operations_director/:community/:device/:playlist" component={Playlist}/>


            <Redirect to="/"/>

            {/* <Route component={NotFound} /> */}
        </Switch>
    );

    return <>{routes}</>;
};

const mapStateToProps = (state) => ({
    dateRange: state.analytics.dateRange,
    isAuthenticated: state.auth.token !== null,
    getData: state.analytics.getData
})

export default connect(mapStateToProps, actions)(Routes)
 

