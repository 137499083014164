import React from "react";
import {Link} from 'react-router-dom'
// import ProgressBar from "../ProgressBar";
import {connect} from "react-redux";
import * as actions from "../../../redux/actions";
import {timing} from "../../../utils/utility";


export const DeviceItem = ({devices, dateRange, setActiveDevice, setSkip, activeRegion, activeOperationsDirector, activeCommunity, deviceSN, timePlayed}) => {

    const device = devices[activeRegion][activeOperationsDirector][activeCommunity][deviceSN]
    const {name, activePlaylists, playlistsCount} = device
    return (
        <Link to={`/${activeRegion}/${activeOperationsDirector}/${activeCommunity}/${deviceSN}`} timePlayed={timePlayed} className='deviceItem' onClick={() => {
            setActiveDevice(deviceSN)
            setSkip(true)
        }}>
            <div className='deviceItem__name'>
                <h2>{name}</h2>
            </div>
            <div className='flex-sBetween grayColor deviceItem__info'>
                <p className='deviceItem__info__playlist'>
                    <span>{activePlaylists} playlists</span></p>
            </div>
        </Link>
    )
}

const mapStateToProps = (state) => ({
    dateRange: state.analytics.dateRange,
    activeRegion: state.analytics.activeRegion,
    activeOperationsDirector: state.analytics.activeOperationsDirector,
    activeCommunity: state.analytics.activeCommunity,
    devices: state.analytics.devices,
});

export default connect(mapStateToProps, actions)(DeviceItem)