import React from "react";
import {Link} from 'react-router-dom'
// import ProgressBar from "../ProgressBar";
import {connect} from "react-redux";
import * as actions from "../../../redux/actions";
import {calculateAnalytics, lastSessionTiming, timing} from "../../../utils/utility";
import {Button, Modal} from "@material-ui/core";


export const PlaylistItem = ({name, activeRegion, activeOperationsDirector, activeCommunity, activeDevice, devices, id, dateRange, setActivePlaylist, timePlayed}) => {

    const playlist = devices[activeRegion][activeOperationsDirector][activeCommunity][activeDevice].playlists[name]
    const {lastSession} = playlist
    const {startTime, sessionLength} = lastSession
    const {omi} = calculateAnalytics(playlist)
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <>
            <div className='flex-sBetween playlistItem__firstLine'>
                <h2>{name}</h2>
                <div className='flex-sBetween infoBlock'>
                    <h3>OMI {omi}</h3>
                    {/*<ProgressBar/>*/}
                </div>
            </div>

            <div className='flex-sBetween playlistItem__secondLine grayColor'>
                <p>{lastSessionTiming(startTime, sessionLength)}</p>
            </div>
        </>
    )

    const render = () => {
        if (true) {
            return (
                <Link to={`/${activeRegion}/${activeOperationsDirector}/${activeCommunity}/${activeDevice}/${name}`} timePlayed={timePlayed} className='playlistItem' onClick={() => setActivePlaylist(name)}>
                    {body}
                </Link>
            )
        } else {
            return (
                <>
                    <div className='playlistItem' onClick={handleOpen}>
                        {body}
                    </div>
                    <Modal open={open} onClose={handleClose}>
                        <div className='modalAlert'>
                            <h3>There’s nothing to see in this playlist</h3>
                            <br/>
                            <p>It hasn’t been played during the selected time range, so no analytics have been
                                recorded.</p>
                            <br />
                            <div className='flex' style={{justifyContent: 'flex-end'}}>
                                <Button variant="contained" color='primary' onClick={handleClose}>Close</Button>
                            </div>
                        </div>
                    </Modal>
                </>
            )
        }
    }

    return render()
}
const mapStateToProps = (state) => ({
    dateRange: state.analytics.dateRange,
    activeRegion: state.analytics.activeRegion,
    activeOperationsDirector: state.analytics.activeOperationsDirector,
    activeCommunity: state.analytics.activeCommunity,
    activeDevice: state.analytics.activeDevice,
    devices: state.analytics.devices,
});

export default connect(mapStateToProps, actions)(PlaylistItem)