import React, {useState} from 'react'
import TopPlayedChart from '../../components/UI/TopPlayedChart'
import {connect} from 'react-redux'
import Loader from "../../components/UI/Loader"
import * as actions from '../../redux/actions/index'
import DropdownFilter from '../../utils/dropdown.class'
import Error from "../../components/UI/Error"
import CommunityItem from '../../components/UI/CommunityItem/CommunityItem'
import { communityToDeviceObject} from '../../utils/utility'
import { useHistory } from 'react-router-dom'
import {sum_devices} from '../../utils/utility'
import {Modal} from "@material-ui/core";
import TopPlayedGames from "../../components/UI/TopPlayedGames";
import GameRanking from '../../components/UI/GameRanking'

const Community = ({loading, getData, error, devices, activeRegion, activeOperationsDirector, setActiveCommunity, dateRange, setAccountChart, accountChart, skip, gamesSorting, setGamesSorting}) => {

    const communities = devices[activeRegion][activeOperationsDirector]
    let graphData = Object.keys(communities).sort().map((community) => communityToDeviceObject(community, communities[community]))
    const history = useHistory();

    const comms = Object.keys(communities)
    if(comms.length === 1 && skip)
    {
        const community = comms[0];
        setActiveCommunity(community);
        history.replace({
            ...history.location,
            pathname: `/${activeRegion}/${activeOperationsDirector}/${community}`,
        });
    }

    let _device = {...graphData[0]}
    sum_devices(_device, graphData)
    const {gamesTop} = _device

    
    //MODAL HANDLER
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        gamesTop.forEach(game => game.show = true)
        setModalOpen(false);
    };
    //MODAL HANDLER END

    // CHART
    const accountChartDropdown = new DropdownFilter({
        // filterNames: ['Top played devices', 'Top OMI score', 'Increased OMI'],
        filterNames: ['Top played devices', 'Top OMI score'],
        option: accountChart,
        setOption: setAccountChart,
        sourceArray: graphData
    })
    // CHART END
    
    const deviceWrapper = () => {
        return Object.keys(communities).sort().map((community, i) => {
            return (
                <CommunityItem key={community + i} community={community} timePlayed={graphData[i].timePlayed}/>
            )
        }).sort((item1, item2) => item2.props.timePlayed - item1.props.timePlayed)
    }


    if (loading) {
        return <Loader/>
    }
    if (error) {
        return <Error/>
    }

    //GAMES DROPDOWN
    const gamesSortDropdown = new DropdownFilter({
        // filterNames: ['Top OMI score', 'Most played games', 'Increased OMI games'],
        filterNames: ['Top OMI score', 'Most played games'],
        option: gamesSorting,
        setOption: setGamesSorting,
        sourceArray: gamesTop
    }) 
    //GAMES DROPDOWN END

    return (
        <div className='flex'>
            <div className='mainWrapper'>
                <TopPlayedChart chartTypeButton={accountChartDropdown.render()}
                    chartData={accountChartDropdown.chartData()}/>
                <TopPlayedGames gamesTop={gamesSortDropdown.sortedArray()} dropdown={gamesSortDropdown.render()}
                    openDialog={handleModalOpen}/>
            </div>
            <div className='sideBarWrapper'>
                <div className='devicesWrapper'>
                    {deviceWrapper()}
                </div>
            </div>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
            >
                <div className='modalWrapper flex'>
                    <GameRanking gamesArr={gamesSortDropdown.sortedArray()} dropdown={gamesSortDropdown.render()}
                                 dateRange={dateRange}/>
                </div>
            </Modal> 
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.analytics.loading,
    getData: state.analytics.getData,
    error: state.analytics.error,
    devices: state.analytics.devices,
    activeRegion: state.analytics.activeRegion,
    activeOperationsDirector: state.analytics.activeOperationsDirector,
    dateRange: state.analytics.dateRange,
    accountChart: state.analytics.accountChart,
    skip: state.analytics.skip,
    gamesSorting: state.analytics.gamesSorting,

})

export default connect(mapStateToProps, actions)(Community)