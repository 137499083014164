import React from "react";
import DonutChart from "../../components/Charts/DonutChart";
import Score from "../../components/UI/Score/";
import ShortScore from "../../components/UI/ShortScore/";
import GameRankingCompare from "../../components/UI/GameRankingCompare";
import LineArea from "../../components/Charts/LineArea";
import {dataForChart} from '../../utils/utility'
import { calculateAnalytics } from "../../utils/utility";

const PlaylistCompare = ({playlist, dateRange}) => {
    const {name, deviceName, chartData, gamesTop} = playlist
    const {engagement, movement, memory, distraction, omi} = calculateAnalytics(playlist);

    if (!omi) {
        return (
            <div className='flex compareWrapper'>
                <p className='flex deviceAndPlaylistName'><span className='grayColor'>{deviceName}</span>&nbsp;/ {name}
                </p>
                <div className='flex compare'>
                    <div className='compare__heatmap'>
                        <h3>There’s nothing to see in this playlist</h3>
                        <br/>
                        <p>It hasn’t been played during the selected time range, so no analytics have been recorded.</p>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='flex compareWrapper'>
            <p className='flex deviceAndPlaylistName'><span className='grayColor'>{deviceName}</span>&nbsp;/ {name}</p>
            <div className='flex compare'>
                {/*First Block*/}
                <div className='flex compare__first'>
                    <div className='flex compare__first__donutWrapper'>
                        <DonutChart labels={['Engagement', 'Memory', 'Distraction', 'Movement']}
                                    data={dataForChart([engagement, memory, distraction, movement])} type={'pie'}
                                    showLegend={false} width={150}
                                    height={165}/>
                    </div>
                    <div className='flex compare__first__scores'>
                        <Score name='OMI' value={omi}/>
                        <div className='flex compare__first__scores__mini'>
                            <ShortScore name='Engagement' value={engagement} color='Blue'/>
                            <ShortScore name='Memory' value={memory} color='Purple'/>
                        </div>
                        <div className='flex compare__first__scores__mini'>
                            <ShortScore name='Distraction' value={distraction} color='Red'/>
                            <ShortScore name='Movement' value={movement} color='Yellow'/>
                        </div>
                    </div>
                </div>
                {/*Second Block*/}
                <div className='compare__heatmap'>
                    {chartData.memory.length > 1 ?
                        <LineArea series={chartData} height={450} width={650}/> :
                        <p>More information will be displayed here once the playlist has more than one day sessions.</p>
                    }
                </div>
                {/*Third Block*/}
                <div className='compare__third'>
                    <GameRankingCompare games={gamesTop} dateRange={dateRange}/>
                </div>
            </div>
        </div>
    )
}

export default PlaylistCompare