import React, {useEffect, useState, memo} from "react";
import {TextField} from "@material-ui/core";
import http from "../../services/httpAgent.service";
import Loader from "../../components/UI/Loader";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    inputStyle: {
        marginLeft: '3vw',
        width: '4vw'
    }
}))

const Multiplier = memo(() => {

    const classes = useStyles()

    const [values, setValues] = useState()

    useEffect(() => {
        http.get(`${process.env.REACT_APP_ANALYTICS_SERVICE}/multiplier`)
            .then(body => setValues(body[0].games))
    }, [])

    const changeValue = (e, arrayIndex, difficultyName, parameterName) => {

        const newScore = +e.target.value
        const newValues = [...values]

        newValues[arrayIndex][difficultyName][parameterName] = newScore
        http.post(`${process.env.REACT_APP_ANALYTICS_SERVICE}/multiplier`, {games: values})
    }

    const scoresInput = (Difficulty, difficultyName, i) => {
        const {engagement, memory, distraction} = Difficulty //Difficulty is not a class, only an object

        return (
            <div>
                <h3 className='multi__difficulty'>{difficultyName}</h3>
                <div className='multi__parameterWrapper'>
                    <TextField defaultValue={engagement} label='engagement' type='number' className={classes.inputStyle}
                               onChange={(e) => {
                                   changeValue(e, i, difficultyName, 'engagement')
                               }}
                    />
                    <TextField defaultValue={memory} label='memory' type='number' className={classes.inputStyle}
                               onChange={(e) => {
                                   changeValue(e, i, difficultyName, 'memory')
                               }}
                    />
                    <TextField defaultValue={distraction} label='distraction' type='number' className={classes.inputStyle}
                               onChange={(e) => {
                                   changeValue(e, i, difficultyName, 'distraction')
                               }}
                    />
                </div>
            </div>
        )
    }

    const gameScores = () => {
        if (!values) {
            return null
        }
        return values.map((game, i) => {
            const {gameName, Elementry, Easy, Medium, Hard} = game // Elementry and other vars are not classes, only an object
            return (
                <div className='multi' key={gameName + i}>
                    <h2 className='multi__gameName'>{gameName}</h2>

                    {scoresInput(Elementry, 'Elementry', i)}
                    {scoresInput(Easy, 'Easy', i)}
                    {scoresInput(Medium, 'Medium', i)}
                    {scoresInput(Hard, 'Hard', i)}

                </div>
            )
        })
    }


    if (values) {
        return gameScores()
    } else {
        return <Loader/>
    }
})

export default Multiplier