import React from "react";
import Chart from "react-apexcharts";
import {useTheme} from "@material-ui/core/styles";
import { dataForChart, sortGraph } from "../../../utils/utility";

const Barchart = ({data, labels, type = 'bar', height = '90%', width = '90%', offsetX = 0}) => {
    const {infoBlue, infoPurple, infoRed, infoYellow} = useTheme().palette.common
    const colors = [infoBlue, infoPurple, infoRed, infoYellow]
    sortGraph(data,labels)
    return (
        <Chart
            options={{
                legend: {
                    show: false
                },
                dataLabels: {
                    style: {
                      colors: ['FFFFFF']
                    },
                    offsetY: 10
                  },
                plotOptions: {
                    bar: {
                        distributed: true
                    }
                  },
                colors: colors,
                chart: {
                    offsetX: offsetX,
                },
                labels,

            }}
            height = {height}
                
            series={[{data: data.map((x) => x)}]}
            type={type}
        />
    );
};
export default Barchart;
