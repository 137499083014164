import axios from 'axios';

// const base = 'https://dev.obie.plugandplayground.com';
// const BASE_URL = (path) => `${base}${path}`;

const get = async (url, headers) => {
    try {
        const res = await axios.get(url, {headers});

        return res.data;
    } catch (error) {
        // if (error.response.status === 401) {
        //     localStorage.removeItem('token');
        //     window.location.href = '/';
        // }
        return error.response.data;
    }
};

const post = async (url, body, headers) => {
    try {
        const res = await axios.post(url, body, {headers});
        return res.data;
    } catch (error) {
        // if (error.response.status === 401) {
        //     localStorage.removeItem('token');
        //     window.location.href = '/';
        // }
        return error.response.data;
    }
};

const http = {get, post}
export default http