import React from "react";
import {InputAdornment, TextField} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/Sort";


export const SearchInput = ({array, setArray, dropdown = false, searchItemName = ''}) => {

    const searchItem = (array, inputValue, setArrayHandler) => {
        const filteredArray = array.map((item) => {
            item.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                ? (item.show = true)
                : (item.show = false)

            return item;
        });

        return setArrayHandler(filteredArray)
    };

    return (
        <div className='flex-sBetween searchBar'>
            <TextField
                autoFocus={true}
                color='primary'
                className='searchBar__textField'
                placeholder={`Search ${searchItemName}...`}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon color='primary'/>
                        </InputAdornment>
                    ),
                }}
                onChange={(e) => {
                    searchItem(array, e.target.value, setArray)
                }}
            />
            {dropdown ?
                <div className='flex-center searchBar__dropdownFilter'>
                    <SortIcon/>
                    {dropdown}
                </div> : null}

        </div>
    )


}