import React from 'react';

// Material
import {Input} from '@material-ui/core'
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';

const input = (props) => {
  let inputElement = null;

  // if (props.invalid && props.shouldValidate && props.touched) {
  //   inputClasses.push(classes.Invalid);
  // }

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <Input
          value={props.value}
          onChange={props.changed}
          {...props.elementConfig}
        />
      );
      break;

    // case 'textarea':
    //   inputElement = (
    //     <textarea
    //       className={inputClasses.join(' ')}
    //       {...props.elementConfig}
    //       value={props.value}
    //       onChange={props.changed}
    //     />
    //   );
    //   break;
    // case 'select':
    //   inputElement = (
    //     <select
    //       className={inputClasses.join(' ')}
    //       value={props.value}
    //       onChange={props.changed}
    //     >
    //       {props.elementConfig.options.map((option) => (
    //         <option key={option.value} value={option.value}>
    //           {option.displayValue}
    //         </option>
    //       ))}
    //     </select>
    //   );
    //   break;
    default:
      inputElement = (
        <input
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }

  return <>{inputElement}</>;
};

export default input;
