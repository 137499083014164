import React, {useState} from "react"
import SearchInput from "../SearchInput"
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    FormControlLabel,
    Button,
    Modal
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {makeStyles} from "@material-ui/core/styles"
import PlaylistNotFound from "../PlaylistNotFound"
// import ProgressBar from "../ProgressBar"
import {connect} from "react-redux";
import * as actions from "../../../redux/actions";
import {withRouter} from "react-router-dom";
import PlaylistCompare from "../../../containers/PlaylistCompare";
import {lastSessionTiming, timing} from "../../../utils/utility";

const useStyles = makeStyles((theme) => ({
    accordion: {
        color: `${theme.palette.common.gray3}`
    },
    accordionExpanded: {
        color: `${theme.palette.common.black}`,
    },
    width100: {
        width: '100%'
    },
    buttonMargin: {
        margin: '0 2vw'
    }

}))

const ComparePlaylistsModal = ({activePlaylist, playlists, devices, dateRange}) => {

    const classes = useStyles()

    const [selectedPlaylists, setSelectedPlaylists] = useState([])
    // const [scroll, setScroll] = useState(0)
    const [playlistsToCompare, setPlaylistsToCompare] = useState(playlists)
    const [modalOpen, setModalOpen] = useState(false)

    //ModalHandlerFunction
    const handleModalOpen = () => {
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }

    const handleChange = (i) => {
        const arrHandler = [...playlistsToCompare]
        arrHandler[i].selected = !arrHandler[i].selected
        const after = arrHandler.splice(i, arrHandler.length)
        const before = arrHandler.splice(0, i)
        setSelectedPlaylists([...before, ...after].filter(playlist => playlist.selected))
        setPlaylistsToCompare([...before, ...after])
    }

    const playlistRender = () => {

        devices.forEach(device => {
            const usefulDevice = playlistsToCompare.some(playlist => playlist.device === device.deviceSN ? playlist.show : null)

            return usefulDevice ? device : device.show = !device.show
        })

        const ComparePlaylistItem = ({playlist}) => {
            const {name, timePlayed, lastSession} = playlist
            const {startTime, sessionLength} = lastSession
            return (
                <div
                    className={`comparePlaylists__secondBlock__item ${playlist.selected ? 'playlistSelected' : 'playlistSelectedNOT'}`}>
                    <div className='flex-sBetween'>
                        <h4>{name}</h4>
                        {/*<div className='flex-sBetween infoBlock'><h4 className='blackColor'>OMI {omi}</h4>*/}
                        {/*    <ProgressBar/>*/}
                        {/*</div>*/}
                    </div>
                    <div className='flex-sBetween'>
                        <p>{timing(timePlayed)} in the last {dateRange} days</p>
                        <p>{lastSessionTiming(startTime, sessionLength)}</p>
                    </div>

                </div>

            )
        }

        const yes = devices.map((device, i) => {
            if (!device.show) {
                return null
            }
            return (
                <Accordion TransitionProps={{unmountOnExit: true}} key={device.name + i} square={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        classes={{root: classes.accordion, expanded: classes.accordionExpanded}}
                    >
                        <div className='accordionDevice'>
                            <h3>{device.name}</h3>
                            <p><span className='bold'>
                                {playlistsToCompare
                                    .filter(playlist => playlist.show &&
                                        device.deviceSN === playlist.device).length}
                                &nbsp;playlists</span> /&nbsp;
                                {playlistsToCompare
                                    .filter(playlist => playlist.show &&
                                        playlist.selected &&
                                        device.deviceSN === playlist.device).length}
                                &nbsp;playlists selected</p>
                        </div>
                    </AccordionSummary>
                    {
                        playlistsToCompare.map((playlist, i) => {
                            if (playlist.id === activePlaylist.id) {
                                // playlist.deviceName = device.name
                                playlist.selected = true
                                // playlist.show = true
                                return null
                            }
                            if (!playlist.show || device.deviceSN !== playlist.device) {
                                return null
                            }
                            playlist.deviceName = device.name
                            return (
                                <AccordionDetails key={playlist.name + i}>
                                    <FormControlLabel
                                        classes={{root: classes.width100, label: classes.width100}}
                                        label={<ComparePlaylistItem playlist={playlist}
                                                                    key={playlist.name + i}/>}
                                        key={playlist.name + i}
                                        control={<Checkbox name={`${playlist.name} + ${i}`}
                                                           color="primary"
                                                           checked={playlist.selected}
                                                           onChange={() => {
                                                               handleChange(i)
                                                           }}/>}
                                    />
                                </AccordionDetails>
                            )

                        })
                    }
                </Accordion>
            )

        })

        const foundSomething = playlistsToCompare.some(item => item.show)

        return foundSomething ? yes : <PlaylistNotFound/>
    }


    const playlistToCompare = selectedPlaylists.sort((a, b) => b.omi - a.omi).map((playlist, i) => {
        return (
            <PlaylistCompare playlist={playlist} key={playlist.name + i} dateRange={dateRange}/>
        )
    })


    // const mainElement = useRef()
    //
    // const scrollerPositionDetector = () => {
    //     setScroll(mainElement.current.scrollTop)
    // }
    // ref={mainElement} onScroll={scrollerPositionDetector}

    return (
        <div className='flex comparePlaylists'>
            {/*<div className={`comparePlaylists__searchWrapper ${scroll > 0 ? 'fancyShadowOnlyBottom' : ''}`}>*/}
            <div className='comparePlaylists__searchWrapper'>
                <p>Select playlists to compare to <span>{activePlaylist.name}</span></p>
                <SearchInput array={playlistsToCompare} setArray={setPlaylistsToCompare} searchItemName='playlists'/>
            </div>

            <div className='comparePlaylists__secondBlock'>
                {playlistRender()}
            </div>

            <div className='comparePlaylists__footer flex'>
                <p>
                    {selectedPlaylists.length > 1 ?
                        `${selectedPlaylists.length - 1} playlists selected` :
                        'No playlist selected'}
                </p>
                <Button variant="contained" color='primary' disabled={selectedPlaylists.length < 2}
                        className={classes.buttonMargin} onClick={handleModalOpen}>
                    Compare
                </Button>

                <Modal open={modalOpen}
                       onClose={handleModalClose}
                >
                    <div className='modalWrapper__compare flex'>
                        <div className='flex comparePlaylists__modal'>
                            {playlistToCompare}
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    activePlaylist: state.analytics.activePlaylist,
    playlists: state.analytics.playlists,
    devices: state.analytics.devices,
    dateRange: state.analytics.dateRange,
});


export default connect(mapStateToProps, actions)(withRouter(ComparePlaylistsModal))
