import {Container, CssBaseline} from '@material-ui/core';
import DashboardAppBar from '../../components/UI/AppBar/AppBar';
import Header from '../../components/UI/Header'


export default function Layout(props) {
    return (
        // React Material Drawer will go here
        <>
            <DashboardAppBar/>
            <Header/>
            <CssBaseline/>
            <Container maxWidth="lg">
                <div>{props.children}</div>
            </Container>
        </>

        // Footer will go here
    );
}
