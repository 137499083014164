import { makeStyles } from '@material-ui/core/styles';
import warningIcon from '../../../assets/image/warning.svg';

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: theme.palette.common.red,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '24px',
    '& img': {
      marginRight: '8px',
    },
    '& span': {
      display: 'block',
    },
    '& span:first-child': {
      textTransform: 'capitalize',
    },
  },
}));

export default function ErrorText(props) {
  const classes = useStyles();

  return (
    <div className={classes.errorText}>
      <img src={warningIcon} alt="warning" />
      <div className="textWrapper">
        <span>{props.message}</span>
        <span>Please double-check and try again.</span>
      </div>
    </div>
  );
}
