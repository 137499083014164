import React from "react";
import {Link} from 'react-router-dom'
// import ProgressBar from "../ProgressBar";
import {connect} from "react-redux";
import * as actions from "../../../redux/actions";
import {operationsDirectorDeviceCount} from "../../../utils/utility";


export const OperationsDirectorItem = ({devices, operations_director, setActiveOperationsDirector, activeRegion, setSkip, timePlayed}) => {

    const communities_count = Object.keys(devices[activeRegion][operations_director]).length
    const devicesCount = operationsDirectorDeviceCount(devices[activeRegion][operations_director])
    return (
        
        <Link to={`/${activeRegion}/${operations_director}`} timePlayed={timePlayed} className='deviceItem' onClick={() => {
            setActiveOperationsDirector(operations_director)
            setSkip(true)
        }}>
            <div className='deviceItem__name'>
                <h2>{operations_director}</h2>
            </div>
            <div className='flex-sBetween grayColor deviceItem__info'>
                <p className='deviceItem__info__playlist'>
                    <span>{communities_count}</span> {communities_count == 1 ? 'community' : 'communities'}</p>
                <div className='flex-sBetween deviceItem__info__progress'>
                    <p className='deviceItem__info__progress__hours'>{devicesCount} {devicesCount == 1 ? 'device' : 'devices'}</p>
                    {/*<ProgressBar/>*/}
                </div>
            </div>
        </Link>
    )
}

const mapStateToProps = (state) => ({
    devices: state.analytics.devices,
    dateRange: state.analytics.dateRange,
    activeRegion: state.analytics.activeRegion
});

export default connect(mapStateToProps, actions)(OperationsDirectorItem)