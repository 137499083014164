import React from "react";
// import ProgressBar from "../ProgressBar";
import noImage from '../../../assets/image/noImage.svg'
import {timing} from '../../../utils/utility'
const TopPlayedGames = ({gamesTop, dropdown, openDialog}) => {

    const gamesDemoList = gamesTop.slice(0, 5).map((game, i) => {
        return (
            <div className='flex gamesWrapper__content__game' key={game.name + i}>
                <img src={game.thumbnailURL ? game.thumbnailURL : noImage} alt='gameLittle'/>
                <div className='flex gamesWrapper__content__game__info'>
                    <h4>{game.name}</h4>
                    <div className='flex'>
                        <p className='grayColor'>{timing(game.timePlayed)}</p>
                        {/*<ProgressBar bold={false}/>*/}
                    </div>
                </div>
            </div>
        )
    })


    return (
        <div className='flex gamesWrapper'>

            <div className='gamesWrapper__dropdown'>
                {dropdown}
            </div>

            <div className='flex gamesWrapper__content'>
                {gamesDemoList}
            </div>

            <p className='flex gamesWrapper__more' onClick={openDialog}>More</p>
        </div>
    )
}

export default TopPlayedGames