const scale_factor = 100;

export const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties,
	};
};

// Form validity check
export const checkFieldValidity = (value, rules) => {
	let isValid = true;

	if (!rules) {
		return true;
	}

	if (rules.required) {
		isValid = value.trim() !== "" && isValid;
	}

	if (rules.minLength) {
		isValid = value.length >= rules.minLength && isValid;
	}

	if (rules.maxLength) {
		isValid = value.length <= rules.maxLength && isValid;
	}

	if (rules.isEmail) {
		const pattern =
			/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
		isValid = pattern.test(value) && isValid;
	}

	if (rules.isNumeric) {
		const pattern = /^\d+$/;
		isValid = pattern.test(value) && isValid;
	}

	return isValid;
};

export const checkFormValidity = (fields) => {
	for (const field in fields) {
		console.log(field);
	}
};

//Null or negative escape for charts (it drives them crazy)
export const dataForChart = (params) =>
	params.map((int) => (int < 0 || isNaN(int) ? 0 : int));

//Time declaration
export const timing = (timeInMillisec) => {
	const timeInMinutes = timeInMillisec / 1000 / 60;
	const timeInHours = timeInMinutes / 60;

	if (timeInMinutes < 60 && timeInMinutes > 1) {
		return `Played ${
			~~timeInMinutes === 1
				? `${~~timeInMinutes} minute`
				: `${~~timeInMinutes} minutes`
		}`;
	} else if (timeInMinutes > 60) {
		return `Played ${
			~~timeInHours === 1
				? `${~~timeInHours} hour`
				: `${~~timeInHours} hours`
		}`;
	} else {
		return `Haven't played`;
	}
};

export const lastSessionTiming = (startTime, sessionLength) => {
	const timeInMinutes = sessionLength / 1000 / 60;
	const timeInHours = timeInMinutes / 60;

	const date = new Date(startTime).toLocaleDateString();
	const hour = new Date(startTime).toLocaleTimeString([], {
		hour: "2-digit",
		minute: "2-digit",
	});

	if (timeInMinutes < 60 && timeInMinutes > 1) {
		return `Last played on ${date} at ${hour} for ${
			~~timeInMinutes === 1
				? `${~~timeInMinutes} minute`
				: `${~~timeInMinutes} minutes`
		}`;
	} else if (timeInMinutes > 60) {
		return `Last played on ${date} at ${hour} for ${
			~~timeInHours === 1
				? `${~~timeInHours} hour`
				: `${~~timeInHours} hours`
		}`;
	} else {
		return `No information about the last session`;
	}
};
//Time declaration END

export const gameHandler = (game) => ({
	...game,
	show: true,
});

export const playlistHandler = (playlist) => {
	return {
		...playlist,
		show: true,
		selected: false,
		gamesTop: playlist.games.map((game) => gameHandler(game)),
		chartData: chartDataHandler(playlist.games, playlist.name),
	};
};

export const deviceHandler = (device) => ({
	show: true,
	powerStateReports: device.powerStateReports,
	region: device.region,
	community: device.community,
	name: device.deviceName,
	deviceSN: device.deviceSN,
	activePlaylists: device.activePlaylists, //Used to render int. No connection to activePlaylist
	playlistsCount: device.playlists.length,
	positive: device.positive,
	totalPositive: device.totalPositive,
	negative: device.negative,
	totalNegative: device.totalNegative,
	engagement: device.engagement,
	movement: device.movement,
	possible_movement: device.possible_movement,
	timePlayed: device.timePlayed,
	omi: device.playlists.reduce((acc, playlist) => {
		return acc + playlist.omi;
	}, 0),
	gamesTop: device.playlists.flatMap((playlist) =>
		playlist.games.map((game) => gameHandler(game))
	),
	playlists: device.playlists.map((playlist) => ({
		...playlist,
		show: true,
		gamesTop: playlist.games.map((game) => gameHandler(game)),
		chartData: chartDataHandler(playlist.games, playlist.name),
	})),
});

const chartDataHandler = (games) => {
	const playlistSessions = [];
	games.forEach((game) => {
		if (game.sessions.length) {
			game.sessions.forEach((session) => {
				session.dayInTheYear = (function () {
					const now = new Date(session.endTime);
					const start = new Date(now.getFullYear(), 0, 0);
					const diff =
						now -
						start +
						(start.getTimezoneOffset() - now.getTimezoneOffset()) *
							60 *
							1000;
					const oneDay = 1000 * 60 * 60 * 24;
					return Math.floor(diff / oneDay);
				})();
				session.endDate = new Date(session.endTime).toLocaleDateString(
					"en-GB",
					{ day: "numeric", month: "short" }
				);
				session.startDate = new Date(
					session.startTime
				).toLocaleDateString("en-GB", {
					day: "numeric",
					month: "short",
				});
				playlistSessions.push(session);
			});
		}
	});
	playlistSessions.sort((a, b) => a.dayInTheYear - b.dayInTheYear);

	const positive = chartDataHandlerParameter(playlistSessions, "positive");
	const totalPositive = chartDataHandlerParameter(
		playlistSessions,
		"totalPositive"
	);
	const negative = chartDataHandlerParameter(playlistSessions, "negative");
	const totalNegative = chartDataHandlerParameter(
		playlistSessions,
		"totalNegative"
	);
	const engagement = chartDataHandlerParameter(
		playlistSessions,
		"engagement"
	);
	const movement = chartDataHandlerParameter(playlistSessions, "movement");
	const possible_movement = chartDataHandlerParameter(
		playlistSessions,
		"possible_movement"
	);
	const timePlayed = chartDataHandlerParameter(
		playlistSessions,
		"sessionLength"
	);

	const _engagement = engagement.map((x, i) => {
		return {
			x: engagement[i].x,
			y: ~~((scale_factor * engagement[i].y) / (timePlayed[i].y / 1000)),
		};
	});
	const _movement = movement.map((x, i) => {
		return {
			x: movement[i].x,
			y: ~~((scale_factor * movement[i].y) / possible_movement[i].y),
		};
	});
	const _memory = positive.map((x, i) => {
		return {
			x: positive[i].x,
			y: ~~((scale_factor * positive[i].y) / totalPositive[i].y),
		};
	});
	const _distraction = negative.map((x, i) => {
		return {
			x: negative[i].x,
			y: ~~((scale_factor * negative[i].y) / totalNegative[i].y),
		};
	});
	const _omi = engagement.map((x, i) => {
		return {
			x: engagement[i].x,
			y:
				totalPositive[i].y === 0 && totalNegative[i].y === 0
					? ~~((_engagement[i].y + _movement[i].y) / 2)
					: ~~Math.max(
							(_engagement[i].y +
								_movement[i].y +
								_memory[i].y -
								_distraction[i].y) /
								3,
							0
					  ),
		};
	});

	return {
		engagement: _engagement,
		movement: _movement,
		memory: _memory,
		distraction: _distraction,
		omi: _omi,
	};
};

const chartDataHandlerParameter = (playlistSessions, parameter) => {
	let prevDate = "";
	let oneDayObject = { x: 0, y: 0 };

	const result = [];

	playlistSessions.forEach((session, i, sessions) => {
		const exactTime = session.endTime;
		const date = session.endDate;

		if (i === 0) {
			prevDate = date;
			oneDayObject = {
				x: new Date(exactTime).getTime(),
				y: session[parameter],
			};
		} else if (date === prevDate && i !== sessions.length - 1) {
			oneDayObject.y += session[parameter];
		} else if (date !== prevDate) {
			result.push(oneDayObject);
			prevDate = date;
			oneDayObject = {
				x: new Date(exactTime).getTime(),
				y: session[parameter],
			};
			if (i === sessions.length - 1) {
				result.push(oneDayObject);
			}
		} else if (i === sessions.length - 1) {
			oneDayObject.y += session[parameter];
			result.push(oneDayObject);
		}
	});
	return result;
};

export const mergeIdenticalGames = (games_arr) => {
	var arr = [...games_arr];
	var new_arr = [];
	for (let i = 0; i < arr.length; i++) {
		if (arr[i] === null) continue;

		var new_item = { ...arr[i] };
		var gameID = new_item.gameId;
		new_arr = new_arr.concat(new_item);

		for (let j = i + 1; j < arr.length; j++) {
			if (arr[j] === null) continue;

			if (arr[j].gameId === gameID) {
				var current = arr[j];
				new_item.positive += current.positive;
				new_item.totalPositive += current.totalPositive;
				new_item.negative += current.negative;
				new_item.totalNegative += current.totalNegative;
				new_item.engagement += current.engagement;
				new_item.movement += current.movement;
				new_item.possible_movement += current.possible_movement;
				new_item.timePlayed += current.timePlayed;
				new_item.sessions = new_item.sessions.concat(current.sessions);
				arr[j] = null;
			}
		}
	}
	return new_arr;
};

export const sortGraph = (values, labels) => {
	for (let i = 0; i < values.length; i++) {
		let max_index = i;
		for (let j = i + 1; j < values.length; j++) {
			if (values[j] > values[max_index]) max_index = j;
		}
		let temp_value = values[i];
		let temp_label = labels[i];

		values[i] = values[max_index];
		labels[i] = labels[max_index];

		values[max_index] = temp_value;
		labels[max_index] = temp_label;
	}
};

export const seperateDevicesToRegions = (devices) => {
	let regions = {};
	devices.forEach((d) => {
		if (d.region === "") d.region = "General";
		if (!(d.region in regions)) regions[d.region] = [d];
		else regions[d.region] = regions[d.region].concat(d);
	});

	return regions;
};

export const seperateDevicesToCommunities = (devices) => {
	let communities = {};
	devices.forEach((d) => {
		if (d.community === "") d.community = "General";
		if (!(d.community in communities)) communities[d.community] = [d];
		else communities[d.community] = communities[d.community].concat(d);
	});
	return communities;
};

export const deviceDictionary = (devices) => {
	let dict = {};
	devices.forEach((d) => {
		dict[d.deviceSN] = d;
	});
	return dict;
};

export const playlistDictionary = (playlists) => {
	if (playlists.length === 0) return {};

	let dict = {};
	playlists.forEach((p) => {
		dict[p.name] = p;
	});
	return dict;
};

export const sum_devices = (device, deviceArr) => {
	for (let i = 1; i < deviceArr.length; i++) {
		let d = deviceArr[i];
		device.positive += d.positive;
		device.totalPositive += d.totalPositive;
		device.negative += d.negative;
		device.totalNegative += d.totalNegative;
		device.engagement += d.engagement;
		device.movement += d.movement;
		device.possible_movement += d.possible_movement;
		device.timePlayed += d.timePlayed;
		if (d.gamesTop) d.gamesTop.forEach((g) => device.gamesTop.push(g));
	}
	device.gamesTop = mergeIdenticalGames(device.gamesTop);
};

export const communityDeviceCount = (communityMap) => {
	return Object.keys(communityMap).length;
};

export const regionDeviceCount = (regionMap) => {
	let count = 0;
	Object.values(regionMap).forEach(
		(community) => (count += communityDeviceCount(community))
	);
	return count;
};

export const communityToDeviceObject = (community, communityMap) => {
	let devices = Object.keys(communityMap).map((key) => communityMap[key]);

	let device = { ...devices[0] };
	if (!device.gamesTop) device.gamesTop = [];
	device.gamesTop = device.gamesTop.map((x) => x);
	device.name = community;

	sum_devices(device, devices);
	return device;
};
export const operationsDirectorDeviceCount = (operationsDirectorMap) =>
{
    let count = 0;
    Object.values(operationsDirectorMap).forEach((community) => count += communityDeviceCount(community))
    return count
}
export const operationsDirectorToDeviceObject = (operations_director, operationsDirectorMap) =>
{
    let devices = Object.keys(operationsDirectorMap).map((key) => communityToDeviceObject(key, operationsDirectorMap[key]))
    let device = {...devices[0]}
    if(!device.gamesTop) device.gamesTop = []
    device.gamesTop = device.gamesTop.map((x) => x)
    device.name = operations_director
    
    sum_devices(device, devices)
    return device
}

export const regionToDeviceObject = (region, regionMap) => {
	let devices = Object.keys(regionMap).map((key) =>
		communityToDeviceObject(key, regionMap[key])
	);
	let device = { ...devices[0] };
	if (!device.gamesTop) device.gamesTop = [];
	device.gamesTop = device.gamesTop.map((x) => x);
	device.name = region;

	sum_devices(device, devices);
	return device;
};

export const calculateAnalytics = (game) => {
	const engagement =
		((scale_factor * game.engagement) / (game.timePlayed / 1000)) | 0;
	const movement =
		((scale_factor * game.movement) / game.possible_movement) | 0;
	const memory = ((scale_factor * game.positive) / game.totalPositive) | 0;
	const distraction =
		((scale_factor * game.negative) / game.totalNegative) | 0;
	let omi = 0;
	if (game.totalPositive === 0 && game.totalNegative === 0) {
		omi = (engagement + movement) / 2;
	} else {
		omi = Math.max((engagement + movement + memory - distraction) / 3, 0);
	}

	return {
		engagement: ~~engagement,
		movement: ~~movement,
		memory: ~~memory | 0,
		distraction: ~~distraction | 0,
		omi: ~~omi,
	};
};
export const seperateDevicesToOperationDirectors = (devices) => 
{
    let operation_directors = {}
    devices.forEach((d) => {
        if(d.operations_director == '') d.operations_director = 'General'
        if(!(d.operations_director in operation_directors)) operation_directors[d.operations_director] = [d]
        else operation_directors[d.operations_director] = operation_directors[d.operations_director].concat(d)
    })
    return operation_directors
}
