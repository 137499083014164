import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { updateObject, checkFieldValidity } from '../../../utils/utility';

// Custom UI
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner.js';
import ErrorText from '../../../components/UI/ErrorText/ErrorText';

// Material UI
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        color: theme.palette.common.black,
        marginBottom: '6px',
        padding: '0',

        '& h2': {
            fontSize: '16px',
            fontWeight: '700',
        },
    },
    dialogContent: {
        padding: '0',
    },
    emailInput: {
        marginBottom: '16px',
        // backgroundColor: theme.palette.common.gray5,
        padding: '8px',
        borderRadius: '8px 8px 0px 0px',

        '& .MuiInput-underline': {
            '&::before': {
                borderBottom: '2px solid red',
            },
        },
    },
    submitEmail: {
        padding: '6px 8px',
        textTransform: 'unset',
        fontSize: '14px',
        color: theme.palette.common.white,
        borderRadius: '8px',
        minWidth: '160px',
        backgroundColor: theme.palette.common.blue1,
        margin: '0',
        '&:hover': {
            backgroundColor: theme.palette.common.blue0,
        },
        '&:disabled': {
            color: theme.palette.common.gray4,
            backgroundColor: theme.palette.common.gray5,
            boxShadow: 'none',
        },
    },
    dialogActions: {
        paddingRight: '0',
    },
    errorMessage: {
        color: theme.palette.common.red,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '24px',
        '& img': {
            marginRight: '8px',
        },
        '& span': {
            display: 'block',
        },
    },
    successMessage: {
        color: theme.palette.common.gray3,
        marginBottom: '24px',
        display: 'block',
    },
}));

export default function ForgotPassword(props) {
    const classes = useStyles();

    // Validation
    const [emailField, setEmailField] = useState({
        elementType: 'input',
        elementConfig: {
            type: 'email',
            placeholder: 'Email',
            id: 'email-input',
            className: `${classes.emailInput}`,
            startAdornment: (
                <InputAdornment position="start" className={classes.inputAdornment}>
                    <EmailIcon />
                </InputAdornment>
            ),
            autoFocus: true,
            fullWidth: true,
        },
        value: '',
        validation: {
            required: true,
            isEmail: true,
        },
        valid: false,
        touched: false,
    });

    const [isEmailValid, setIsEmailValid] = useState(false);

    const inputChangedHandler = (event) => {
        const updatedEmailElement = updateObject(emailField, {
            value: event.target.value,
            valid: checkFieldValidity(event.target.value, emailField.validation),
            touched: true,
        });

        let isEmailValid = true;

        isEmailValid = updatedEmailElement.valid && isEmailValid;

        setEmailField(updatedEmailElement);
        setIsEmailValid(isEmailValid);
    };

    const submitHandler = (event) => {
        event.preventDefault();

        if (props.forgotPassSuccess && !props.loading && !props.error) {
            props.onForgotPassClose();
        } else {
            props.onSubmit(emailField.value);
        }
    };

    let popupSubtitle = (
        <DialogContentText>
            Don’t worry, happens to the best of us.
        </DialogContentText>
    );

    let errorMessage = null;

    if (props.forgotPass && props.error) {
        errorMessage = <ErrorText message={props.error} />;
    }

    let emailInput = (
        <Input
            elementType="input"
            elementConfig={emailField.elementConfig}
            value={emailField.value}
            invalid={!emailField.valid}
            shouldValidate={emailField.validation}
            touched={emailField.touched}
            changed={(event) => inputChangedHandler(event)}
        />
    );

    if (props.forgotPass && !props.error && !props.loading) {
        emailInput = (
            <span className={classes.successMessage}>
        Got it! Our support team will contact this Email <br /> address if it
        exists in our system.
      </span>
        );

        popupSubtitle = null;
    }

    let submitButtonAction = 'Send password to my Email';

    if (props.forgotPass && props.loading) {
        submitButtonAction = <Spinner size={20} />;
    } else if (props.forgotPassSuccess && !props.loading && !props.error) {
        submitButtonAction = 'Close';
    }

    let submitButton = (
        <Button
            clicked={submitHandler}
            color="primary"
            actionText={submitButtonAction}
            className={classes.submitEmail}
            disabled={!isEmailValid}
            disableRipple={true}
        />
    );

    return (
        <>
            <DialogTitle
                id="form-dialog-title"
                classes={{ root: classes.dialogTitle }}
            >
                Recover password
            </DialogTitle>

            <DialogContent classes={{ root: classes.dialogContent }}>
                {popupSubtitle}

                {emailInput}

                {errorMessage}
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                {submitButton}
            </DialogActions>
        </>
    );
}