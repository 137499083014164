import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import MenuDropDown from '../../MenuDropDown/MenuDropDown';

const useStyles = makeStyles((theme) => ({
    root: {
        textTransform: 'unset',
        fontSize: '14px',
        color: `${theme.palette.common.blue1}`,
        // color: '#808893',
    },
    dropDownIcon: {
        marginLeft: '1vw',
    },
}));

function AppBarItem(props) {
    const classes = useStyles();

    const {item, onItemClick} = props;

    const appBarItem = item.isSubMenu ? (
        <MenuDropDown styling={classes} item={item} onClick={() => onItemClick(item.to)} onSubItemClick={onItemClick}/>
    ) : (
        <Button
            color="inherit"
            className={classes.root}
            onClick={() => onItemClick(item.to) }
        >
            {item.name}
        </Button>
    );

    return <> {appBarItem} </>;
}

export default AppBarItem;
